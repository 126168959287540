import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
  Icon,
  Spacer,
  Flex,
  Tooltip,
  Checkbox,
  Box,
  Progress,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  InputGroup,
  SimpleGrid,
  Link,
  Textarea,
  Image,
  CardBody,
  Card as ChakraCard,
  Grid,
  GridItem,
  Heading,
  CardFooter,
} from "@chakra-ui/react";

import Select2, { StylesConfig } from "react-select";

import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import {
  CheckIcon,
  ExternalLinkIcon,
  NotAllowedIcon,
  RepeatClockIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";
import {
  FaAccessibleIcon,
  FaHandPointUp,
  FaMoneyBillWaveAlt,
  FaMoneyCheck,
  FaPlus,
  FaSave,
  FaTrash,
} from "react-icons/fa";
import { getActionsPermission } from "../../../helpers/api/auth";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Rifinha",
  path: "/base/ticket/",
  update: "/base/ticket/update/",
  apiBase: "/bancas/view-tickets/",
  apiBaseDelete: "/bancas/ticket/",
};

const { ptBR } = require("date-fns/locale");

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {format(new Date(props?.value), "dd/MM/yyyy HH:mm")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Rifinha" });
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  /**
   * HANDLERS
   */

  return (
    <React.Fragment>
      {actions.canDelete && (
        <Link
          to="#"
          className="action-icon"
          onClick={() => handleClickDelete(object?.idRaffle)}
        >
          {" "}
          <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
        </Link>
      )}
    </React.Fragment>
  );
};

/**
 * COMPONENT
 */
const Rifinha = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [numbers, setNumbers] = useState("");

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();
  const { publish } = useEvent();
  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, {}).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de rifas.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const handleSearch = async (type: string) => {
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, {}).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados da rifa.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Pules" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleAdd = () => {
    onOpen();
  };

  const handleSaveFarm = async () => {
    const payload = {
      title,
      description,
      price: parseCurrency(price),
      numbers,
      image: selectedFile,
    };

    if (
      !payload.title ||
      !payload.description ||
      !payload.price ||
      !payload.numbers ||
      price === "R$ 0,00"
    ) {
      toast({
        title: "Erro ao salvar rifa.",
        description: "Preencha todos os campos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (
      window.confirm(
        "Você tem certeza de que todos os dados estão corretos? Se tens dúvida, clique em cancelar e verifique novamente."
      ) === false
    )
      return;

    dispatch(toggleLoader(true));

    try {
      await api.createWithFile("/bancas/ticket", payload).then((response) => {
        toast({
          title: "Rifa salva com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
    } catch (e) {
      toast({
        title: "Erro ao salvar rifa.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    publish("deleteItem", null);
    dispatch(toggleLoader(false));
    onClose();
  };

  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>("");

  useEffect(() => {
    if (!selectedFile) {
      setPreview("");
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // handle validations
    if (!e.target.files) return;

    const image = e.target.files[0];
    if (!image) {
      setSelectedFile(undefined);
      return false;
    }
    if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      alert("Selecione uma imagem válida.");
      return false;
    }

    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleClickDelete = async (id: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete(configs.apiBaseDelete + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="mb-4">
                <Col sm={4}>
                  {actions.canWrite && (
                    <ChakraButton
                      mr={2}
                      colorScheme="yellow"
                      leftIcon={<Icon as={FaPlus} />}
                      onClick={handleAdd}
                    >
                      Adicionar Novo
                    </ChakraButton>
                  )}

                  <Modal isOpen={isOpen} onClose={onClose} size="lg">
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Nova Rifinha</ModalHeader>
                      <ModalCloseButton />

                      <ModalBody>
                        <Flex direction="column" gap={2}>
                          {preview && <Image src={preview} width={"100%"} />}

                          <Flex direction="column" gap={1}>
                            <Text fontWeight={"bold"} my={0}>
                              Imagem da Rifa:
                            </Text>
                            <InputGroup>
                              <input
                                placeholder="Imagem de capa"
                                type="file"
                                onChange={(e) => handleFileInput(e)}
                              />
                            </InputGroup>
                          </Flex>

                          <Flex direction="column" gap={1}>
                            <Text fontWeight={"bold"} my={0}>
                              Título:
                            </Text>
                            <InputGroup>
                              <Input
                                placeholder="Título da Rifa"
                                type="text"
                                size="md"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </InputGroup>
                          </Flex>

                          <Flex direction="column" gap={1}>
                            <Text fontWeight={"bold"} my={0}>
                              Descrição:
                            </Text>
                            <InputGroup>
                              <Textarea
                                placeholder="Informe uma descrição."
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </InputGroup>
                          </Flex>

                          <Flex direction="column" gap={1}>
                            <Text fontWeight={"bold"} my={0}>
                              Números:
                            </Text>
                            <Select
                              placeholder="Selecione..."
                              size="md"
                              value={numbers}
                              onChange={(e) => setNumbers(e.target.value)}
                            >
                              {/* <option value={"1MM"}>
                                1 MILHÃO (6 DIG. FEDERAL)
                              </option> */}
                              <option value={"10MM"}>
                                10 MILHÕES (7 DIG. FEDERAL)
                              </option>
                            </Select>
                          </Flex>

                          <Flex direction="column" gap={1}>
                            <Text fontWeight={"bold"} my={0}>
                              Preço:
                            </Text>
                            <InputGroup>
                              <Input
                                placeholder="Valor unitário COTA"
                                type="text"
                                size="md"
                                value={price}
                                onChange={(e) =>
                                  setPrice(
                                    formatCurrency(
                                      parseCurrency(e.target.value || "0")
                                    )
                                  )
                                }
                              />
                            </InputGroup>
                          </Flex>

                          <Box borderTop="1px solid #444" mt={2} pt={2}>
                            <Text my={0} fontWeight={"bold"}>
                              Resumo:
                            </Text>
                            <Text mt={2} mb={0}>
                              Rifa de{" "}
                              <b>
                                {numbers === "1MM" ? "1 milhão" : "10 milhões"}{" "}
                                de números
                              </b>
                              , valor da cota{" "}
                              <b>
                                R$ {formatCurrency(parseCurrency(price) || 0)}
                              </b>{" "}
                              totalizando uma arrecadação de{" "}
                              <b>
                                R${" "}
                                {formatCurrency(
                                  parseCurrency(price) *
                                    (numbers === "1MM" ? 1000000 : 10000000) ||
                                    0
                                )}
                                .
                              </b>
                            </Text>
                            <Text fontWeight={"bold"} mt={1}>
                              Royalts do sistema: R${" "}
                              {formatCurrency(
                                (parseCurrency(price) *
                                  (numbers === "1MM" ? 1000000 : 10000000) *
                                  1) /
                                  100 || 0
                              )}
                              .
                            </Text>
                          </Box>
                        </Flex>
                      </ModalBody>

                      <ModalFooter>
                        <ChakraButton
                          colorScheme="yellow"
                          leftIcon={<Icon as={FaSave} />}
                          onClick={handleSaveFarm}
                        >
                          Salvar
                        </ChakraButton>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Col>
              </Row>

              <Box>
                <Text fontSize="xl" fontWeight="bold">
                  Rifinhas
                </Text>
                <Spacer />

                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  {data.length > 0 &&
                    data.map((item: any, index: number) => (
                      <GridItem key={index} w="100%">
                        <Box>
                          <ChakraCard padding={0} width={"100%"}>
                            <CardBody padding={0}>
                              <Image
                                padding={0}
                                src={item.image}
                                alt="premio"
                                borderTopRadius="lg"
                                width={"100%"}
                                maxH={"400px"}
                                fit={"cover"}
                              />
                              <Stack mt="2" spacing="3">
                                <Heading pb={1} px={5} size="md">
                                  {item.title}
                                </Heading>
                              </Stack>
                            </CardBody>
                            <CardFooter
                              paddingBottom={2}
                              mt={1}
                              borderTop={"1px solid #E2E8F0"}
                              paddingTop={2}
                              alignItems={"space-between"}
                              flexDir={"column"}
                            >
                              <Flex>
                                <Text
                                  bg="red"
                                  p={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  borderRadius={6}
                                  fontSize={"xs"}
                                  style={{
                                    animationName: "blink",
                                    animationDuration: "1s",
                                    animationIterationCount: "infinite",
                                  }}
                                >
                                  {item.type} DE NÚMEROS
                                </Text>

                                <Spacer />

                                <Text
                                  p={1}
                                  borderRadius={6}
                                  textColor={"white"}
                                  backgroundColor={"black"}
                                  fontSize={"xs"}
                                >
                                  COTA: R$ {formatCurrency(item.price)}
                                </Text>
                              </Flex>
                              <Flex>
                                <Text>
                                  {item?.description
                                    .split("\n")
                                    .map((str, index) => (
                                      <Text key={index}>
                                        {str}
                                        {"\n"}
                                      </Text>
                                    ))}
                                </Text>
                              </Flex>
                              <Flex borderTop={"1px solid #000"} pt={2}>
                                <ButtonGroup>
                                  <ChakraButton
                                    colorScheme="red"
                                    leftIcon={<Icon as={FaTrash} />}
                                    onClick={() =>
                                      handleClickDelete(item.idRaffle)
                                    }
                                  >
                                    Excluir
                                  </ChakraButton>
                                </ButtonGroup>
                              </Flex>
                            </CardFooter>
                          </ChakraCard>
                        </Box>
                      </GridItem>
                    ))}
                </Grid>
              </Box>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Rifinha;
