const useEvent = () => {
  const subscribe = (eventName: string, listener: any) => {
    document.addEventListener(eventName, listener);
  };

  const unsubscribe = (eventName: string, listener: any) => {
    document.removeEventListener(eventName, listener);
  };

  const publish = (eventName: string, data: any) => {
    const event = new CustomEvent(eventName, { detail: data });

    document.dispatchEvent(event);
  };

  return {
    subscribe,
    unsubscribe,
    publish,
  };
};

export default useEvent;
