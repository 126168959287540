import { useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  Select,
  useToast,
  Table,
  Button,
  TableContainer,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Divider,
  AbsoluteCenter,
  Box,
  Center,
  Icon,
  Flex,
  Text,
} from "@chakra-ui/react";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { FaClone, FaEdit, FaPlus, FaPrint, FaTrash } from "react-icons/fa";
import LoaderTable from "../../../components/LoaderTable";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Tabelas de pagamento",
  path: "/base/payment-tables/",
  create: "/base/payment-tables/new/",
  update: "/base/payment-tables/update/",
  duplicate: "/base/payment-tables/duplicate/",
  apiBase: "/bancas/payment-tables/",
};

const PaymentTables = () => {
  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const [paymentTables, setPaymentTables] = useState([]);
  const [selectedPaymentTable, setSelectedPaymentTable] = useState("");
  const removeModalitiesCodes = [
    "LOT16",
    "LOT22",
    "LOT21",
    "LOT20",
    "LOT19",
    "LOT18",
    "LOT17",
    "SEN40",
    "SEN35",
    "SEN30",
    "SEN25",
    "SEN20",
    "SEN19",
    "SEN18",
    "SEN17",
    "SEN16",
    "SEN15",
    "SEN14",
    "SEN13",
    "QUI45",
    "QUI40",
    "QUI35",
    "QUI30",
    "QUI25",
    "QUI20",
    "QUI19",
    "QUI18",
    "QUI17",
    "QUI16",
    "QUI15",
    "QUI14",
    "QUI13",
    "QUI12",
  ];

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();
  const { publish } = useEvent();

  const handleDelete = () => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    api
      .delete(configs.apiBase + selectedPaymentTable)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };
  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    await api
      .get(configs.apiBase, null)
      ?.then((response) => {
        setPaymentTables(response?.data ?? []);
        setSelectedPaymentTable(response?.data[0]?.idPaymentTable ?? "");
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registros",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  }, [setPaymentTables]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */
  const handleAdd = useCallback(() => {
    navigate(configs.create);
  }, [navigate]);

  const handleDuplicate = useCallback(() => {
    navigate(configs.duplicate + selectedPaymentTable);
  }, [navigate, selectedPaymentTable]);

  const handleEdit = useCallback(() => {
    navigate(configs.update + selectedPaymentTable);
  }, [navigate, selectedPaymentTable]);

  const handleSelectChange = (value: any) => {
    setSelectedPaymentTable(value);
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <LoaderTable />
              <Flex>
                <Button
                  colorScheme="yellow"
                  leftIcon={<Icon as={FaPlus} />}
                  onClick={handleAdd}
                >
                  Adicionar Novo
                </Button>
              </Flex>
              <Row>
                <Col md={12}>
                  <Text mt={4} mb={1} fontWeight={"bold"}>
                    Cotação selecionada:
                  </Text>
                  <Select onChange={(e) => handleSelectChange(e.target.value)}>
                    {paymentTables.map((item: any) => (
                      <option value={item.idPaymentTable}>{item.name}</option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Flex
                my={4}
                direction={{ base: "column", md: "row" }}
                gap={{ base: 2, md: 2 }}
              >
                <Button
                  colorScheme="teal"
                  leftIcon={<Icon as={FaClone} />}
                  onClick={handleDuplicate}
                >
                  Duplicar
                </Button>

                <Button
                  colorScheme="teal"
                  leftIcon={<Icon as={FaEdit} />}
                  onClick={handleEdit}
                >
                  Editar
                </Button>

                <Button
                  colorScheme="red"
                  leftIcon={<Icon as={FaTrash} />}
                  onClick={handleDelete}
                >
                  Excluir
                </Button>
              </Flex>

              <Row>
                <Col md={12}>
                  <Box position="relative" padding="1">
                    <Divider />
                    <AbsoluteCenter bg="white" px="4" fontWeight={"bold"}>
                      COTAÇÕES
                    </AbsoluteCenter>
                  </Box>
                  <Center>
                    <TableContainer width={"100%"}>
                      <Table size="sm">
                        <Thead>
                          <Tr>
                            {/* <Th>#</Th> */}
                            <Th>Modalidade</Th>
                            <Th w={50}>Cotação</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {paymentTables
                            .filter(
                              (p) =>
                                p.idPaymentTable ===
                                Number(selectedPaymentTable)
                            )[0]
                            ?.cotations.filter(
                              (cotation) =>
                                !removeModalitiesCodes.includes(
                                  cotation.modality.code
                                )
                            )
                            .map((cotation: any) => {
                              return (
                                <Tr _hover={{ bg: "gray.200" }}>
                                  {/* <Td>{cotation.modality.code}</Td> */}
                                  <Td>{cotation.modality.name}</Td>
                                  <Td isNumeric>
                                    R$ {formatCurrency(cotation.value)}
                                  </Td>
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Center>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PaymentTables;
