import { useForm, Resolver } from "react-hook-form";

interface VerticalFromProps<TFormValues> {
  defaultValues?: any;
  resolver?: Resolver<TFormValues>;
}

const useRegisterForm = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  defaultValues,
  resolver,
}: VerticalFromProps<TFormValues>) => {
  const {
    reset,
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    resetField,
    handleSubmit,
  } = useForm<TFormValues>({ defaultValues, resolver });

  return {
    handleSubmit,
    resetField,
    getValues,
    setValue,
    register,
    control,
    errors,
    reset,
  };
};

export default useRegisterForm;
