import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import { Box, Icon, Text, useToast, Button } from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getActionsPermission } from "../../../helpers/api/auth";
import { FaPlus } from "react-icons/fa";
import { format } from "date-fns";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Dispositivos",
  path: "/base/devices/",
  create: "/base/devices/new/",
  update: "/base/devices/update/",
  apiBase: "/bancas/devices/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {props?.value && format(new Date(props?.value), "dd/MM/yyyy HH:mm")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Dispositivos" });
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  /**
   * HANDLERS
   */
  const handleClickDelete = async (id: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete(configs.apiBase + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      {actions.canWrite && (
        <Link to={configs.update + object?.idDevice} className="action-icon">
          {" "}
          <i
            className="mdi mdi-square-edit-outline"
            style={{ color: "#2980b9" }}
          ></i>
        </Link>
      )}

      {actions.canDelete && (
        <Link
          to="#"
          className="action-icon"
          onClick={() => handleClickDelete(object?.idDevice)}
        >
          {" "}
          <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
        </Link>
      )}
    </React.Fragment>
  );
};

const columns = [
  {
    Header: "ID",
    accessor: "idDevice",
  },
  {
    Header: "Tipo",
    accessor: "type",
  },

  {
    Header: "Modelo",
    accessor: "model",
  },
  {
    Header: "Chip",
    accessor: "chip",
  },
  {
    Header: "Unidade/Reg",
    accessor: (row) => ({
      idUnit: row?.unit?.idUnit,
      fullname: row?.unit?.fullName,
      regionName: row?.unit?.region?.name,
    }),
    Cell: ({ value }) => (
      <div>
        <div>
          {value?.idUnit} | {value?.fullname}
        </div>
        <div>{value?.regionName}</div>
      </div>
    ),
  },
  {
    Header: "Descrição",
    accessor: "description",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => (
      <span style={{ color: value === 1 ? "green" : "red" }}>
        {value === 1 ? "Ativo" : "Desativado"}
      </span>
    ),
  },
  {
    Header: "Última Atividade",
    accessor: "updatedAt",
    Cell: ({ row }) => {
      let geolocation = {};

      if (row?.original?.unit?.geolocation) {
        geolocation = JSON.parse(row?.originalw.unit?.geolocation);
      }
      const googleMapsUrl = `https://www.google.com/maps?q=${geolocation?.latitude},${geolocation?.longitude}&z=18`;
      return (
        <a
          style={{ cursor: "pointer", color: "#3c8dbc" }}
          href={googleMapsUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i
            className="fas fa-map-marker-alt"
            style={{ marginRight: "0.2rem" }}
          ></i>
          {DateColumn({
            value: row?.original?.unit?.lastLogin,
            row: row?.original,
          })}
        </a>
      );
    },
  },

  {
    Header: "Ações",
    accessor: "actions",
    sort: false,
    classes: "table-action",
    Cell: ActionColumn,
  },
];

/**
 * COMPONENT
 */
const Devices = () => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Dispositivos" });
  const dispatch = useDispatch<AppDispatch>();

  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useToast();
  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));

    await api
      .get(configs.apiBase, null)
      ?.then((response) => {
        setData(response?.data ?? []);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registros",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  }, [setData]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */
  const handleAdd = useCallback(() => {
    navigate(configs.create);
  }, [navigate]);

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="mb-4">
                <Col sm={4}>
                  {actions.canCreate && (
                    <Button
                      mr={2}
                      colorScheme="yellow"
                      leftIcon={<Icon as={FaPlus} />}
                      onClick={handleAdd}
                    >
                      Adicionar Novo
                    </Button>
                  )}
                </Col>
              </Row>

              <Table
                isSearchable={true}
                columns={columns}
                data={data ?? []}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-nowrap mt-3 table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Devices;
