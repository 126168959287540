import axios from "axios";
import jwtDecode from "jwt-decode";
// CONFIGS
import config from "../../config";

// CONTENT TYPE
axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

// INTERCEPTORS
axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message;
    const controller = new AbortController();

    // if (error && error.response && error.response.status === 404) {
    //   window.location.href = "/not-found";
    // } else
    if (error && error.response && error.response.status === 403) {
      alert("Você não tem permissão para acessar este recurso.");
      window.location.href = "/not-authorized";
      controller.abort();
    } else if (error && error.response && error.response.status === 401) {
      alert("Token de acesso expirado, entre novamente para renová-lo.");
      window.location.href = "/auth/logout";
      controller.abort();
    } else {
      switch (error.response.status) {
        // case 401:
        //   message = "Credenciais inválidas";
        //   break;
        case 403:
          message = "Acesso negado";
          break;
        // case 404:
        //   message =
        //     "Desculpe! os dados que você está procurando não foram encontrados";
        //   break;
        default: {
          message =
            error.response && error.response.data
              ? error.response.data["message"]
              : error.message || error;
        }
      }

      return Promise.reject(message);
    }
  }
);

const AUTH_SESSION_KEY = "@Baron:user";

/**
 * SETS THE DEFAULT AUTHORIZATION
 */
const setAuthorization = (token: string | null) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const getUserFromCookie = () => {
  const user = localStorage.getItem(AUTH_SESSION_KEY);

  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

class APICore {
  /**
   * FETCHES DATA FROM GIVEN URL
   */
  get = (url: string, params: any) => {
    let response;

    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";

      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  getFile = (url: string, params: any) => {
    let response;

    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";

      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";

    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }

    return axios.all(reqs);
  };

  /**
   * POST GIVEN DATA TO URL
   */
  create = (url: string, data: any) => {
    return axios.post(url, data);
  };

  /**
   * UPDATES PATCH DATA
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * UPDATES DATA
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * CLEAR DATA
   */
  delete = (url: string) => {
    return axios.delete(url);
  };

  /**
   * POST GIVEN DATA TO URL WITH FILE
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();

    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };

    return axios.post(url, formData, config);
  };

  /**
   * POST GIVEN DATA TO URL WITH FILE
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();

    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };

    return axios.put(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user) {
      return false;
    }

    // const decoded: any = jwtDecode(user.token);
    // const currentTime = Date.now() / 1000;

    // if (decoded.exp < currentTime) {
    //   console.warn("Token de acesso expirado");
    //   console.log("chameiii");

    //   this.setLoggedInUser(null);
    //   setAuthorization(null);

    //   return false;
    // } else {
    return true;
    // }
  };

  setLoggedInUser = (session: any) => {
    if (session) {
      localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    } else {
      localStorage.removeItem(AUTH_SESSION_KEY);
    }
  };

  /**
   * RETURNS THE LOGGED USER
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = localStorage.getItem(AUTH_SESSION_KEY);

    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);

      this.setLoggedInUser({
        token,
        ...user,
        ...modifiedUser,
      });
    }
  };
}

/**
 * CHECK IF TOKEN IS AVAILABLE IN SESSION
 */
let user = getUserFromCookie();

if (user) {
  const { token } = user;

  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
