import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import DatePicker from "../../../components/Datepicker";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Box,
  ButtonGroup,
  Select,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Flex,
  Spacer,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Table as ChakraTable,
  Badge,
  background,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { EmailIcon } from "@chakra-ui/icons";
import { format } from "date-fns";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Registradas",
  path: "/base/pass-dashboard/",
  apiBase: "/bancas/pass-dashboard/pules/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {new Date(props?.value).toLocaleDateString("pt-BR")}
    </React.Fragment>
  );
};

/**
 * COMPONENT
 */
const RepasseRegistradas = () => {
  /**
   * HOOKS
   */
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const [data, setData] = useState([]);
  const [paginate, setPaginate] = useState<any>({});
  const [stats, setStats] = useState<any>({});
  const [modalities, setModalities] = useState([]);
  const toast = useToast();
  const [lotteries, setLotteries] = useState<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [formUnit, setFormUnit] = useState<string>("");
  const [formLottery, setFormLottery] = useState<string>("");
  const [selectedPule, setSelectedPule] = useState<any>(null);
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const openModal = (pule: any) => {
    setSelectedPule(pule);
    onOpen();
  };

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();
  const { publish } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */

  const columns = [
    {
      id: "pules.idPule",
      Header: "Pule #",
      isSorted: true,
      isSortedDesc: true,
      accessor: "idPule",
      sort: true,
      classes: "fw-semibold",
      Cell: ({ value, row }: { value: any; row: any }) => (
        <Box
          onClick={() => openModal(row.original)}
          cursor={"pointer"}
          color="#3081D0"
        >
          {`${value}-${row.original.idPuleLottery}`}
        </Box>
      ),
    },
    {
      id: "pules.createdAt",
      Header: "Data/Hora",
      accessor: "createdAt",
      sort: true,
      Cell: ({ value, row }: { value: any }) => {
        const date = new Date(value);
        const canceled = row.original.canceled;
        return (
          <>
            <Text color={canceled && "red"} my={0}>
              {date.toLocaleString()}
            </Text>
            <Text color={canceled && "red"} my={0} fontSize={12}>
              {canceled ? "PULE CANCELADA" : ""}
            </Text>
          </>
        );
      },
    },
    {
      id: "units.fullName",
      Header: "Unidade",
      accessor: (row: any) => `${row.idUnit} | ${row.fullName.toUpperCase()}`,
      sort: true,
      classes: "fw-semibold",
    },
    {
      id: "lotteries.code",
      Header: "Loteria",
      accessor: "code",
      sort: true,
      classes: "fw-semibold",
    },
    {
      id: "pules.executionAt",
      Header: "Data valendo",
      accessor: "executionAt",
      sort: true,
      Cell: DateColumn,
    },
    {
      Header: "Cotação",
      accessor: "paymentTable.name",
      sort: false,
      classes: "fw-semibold",
    },

    {
      id: "pules.price_unit",
      Header: "Valor",
      accessor: "price_unit",
      sort: true,
      classes: "fw-semibold",
      Cell: ({ value }) => {
        const formattedValue = formatCurrency(value ?? 0);
        return <span>R$ {formattedValue}</span>;
      },
    },
    {
      Header: "Valor Prêmio",
      accessor: "award_value",
      sort: false,
      classes: "fw-semibold",
      Cell: ({ value }) => {
        const formattedValue = formatCurrency(value ?? 0);
        return <span>R$ {formattedValue}</span>;
      },
    },
  ];
  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    try {
      await api.get("/bancas/lotteries", null)?.then((response) => {
        setLotteries(response.data);
      });
      // realiza consulta dos registros

      let payload = {
        startDate: formStartDate,
        unit: formUnit,
        idLottery: formLottery,
      };

      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data.pules ?? []);
        setStats(response.data.stats ?? []);
        setPaginate(response.data.paginate ?? []);
        setModalities(response.data.modalities ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  }, [setData]);

  const cancelPule = async (idPule: string, idPuleLottery: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete("/bancas/pules/" + idPule + "/" + idPuleLottery)
      ?.then((response) => {
        publish("deleteItem", null);
        onClose();
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const preparePayload = () => {
    let payload = {
      startDate: formStartDate,
      unit: formUnit,
      idLottery: formLottery,
    };

    return payload;
  };

  const handleSearch = async (moreFields?: any) => {
    let payload = preparePayload();
    dispatch(toggleLoader(true));

    try {
      await api
        .create(configs.apiBase, { ...payload, ...moreFields })
        .then((response) => {
          setData(response.data.pules ?? []);
          setStats(response.data.stats ?? []);
          setPaginate(response.data.paginate ?? []);
          setModalities(response.data.modalities ?? []);
        });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const getNameOfModality = (idModality: number) => {
    if (idModality === 0) {
      return "Todas";
    }

    const find = modalities.find(
      (modality: any) => modality.idModality === idModality
    )?.code;

    return find || "Não encontrado";
  };

  const getFormattedDate = (date: any, isTime: boolean) => {
    if (isTime) {
      return (
        new Date(date).toLocaleDateString("pt-BR") +
        " " +
        new Date(date).toLocaleTimeString("pt-BR")
      );
    }
    return new Date(date).toLocaleDateString("pt-BR");
  };

  const handlePageChange = async ({ page, field, order, search }) => {
    return await handleSearch({ page, field, order, search });
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />
      <Row>
        <Col>
          <Row>
            <Col md={6}>
              <StatisticsWidget
                variant="info"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimals: 2,
                  decimal: ",",
                  separator: ".",
                }}
                description="R$ Registradas"
                stats={stats.valor_registradas}
                icon="fe-dollar-sign"
              />
            </Col>
            <Col md={6}>
              <StatisticsWidget
                variant="info"
                counterOptions={{}}
                description="QTD. Registradas"
                stats={stats.registradas ?? 0}
                icon="fe-bar-chart-line"
              />
            </Col>
            <Col md={6}>
              <StatisticsWidget
                variant={stats?.balance?.previous >= 0 ? "success" : "danger"}
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimals: 2,
                  decimal: ",",
                  separator: ".",
                }}
                description="Saldo ontem"
                stats={stats?.balance?.previous ?? 0}
                icon="fe-bar-chart-line"
              />
            </Col>
            <Col md={6}>
              <StatisticsWidget
                variant={stats?.balance?.current >= 0 ? "success" : "danger"}
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimals: 2,
                  decimal: ",",
                  separator: ".",
                }}
                description="Saldo hoje"
                stats={stats?.balance?.current ?? 0}
                icon="fe-bar-chart-line"
              />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Row className="my-2">
                <Text fontWeight={"bold"}>Filtros:</Text>
                <Col sm={12}>
                  <Col sm={6} className="mb-2">
                    <Input
                      className="form-control"
                      type="date"
                      value={formStartDate}
                      onChange={(e) => setFormStartDate(e.target.value)}
                    />
                  </Col>
                  <Col sm={6} className="mb-2">
                    <Select
                      placeholder="Todas as loterias"
                      onChange={(e) => setFormLottery(e.target.value)}
                      value={formLottery}
                    >
                      {lotteries?.categories.map((category: any) => {
                        return (
                          <optgroup
                            label={category.title}
                            key={category.idCategory}
                          >
                            {lotteries?.lotteries
                              .filter(
                                (lot: any) =>
                                  lot.idCategory === category.idCategory
                              )
                              .map((lottery: any, i: number) => {
                                return (
                                  <option value={lottery.idLottery}>
                                    {lottery.title}
                                  </option>
                                );
                              })}
                          </optgroup>
                        );
                      })}
                    </Select>
                  </Col>
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch({})}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>

              <Table
                isSearchable={false}
                columns={columns}
                useSortBy={true}
                data={data}
                paginate={paginate}
                isSortable={true}
                pagination={true}
                tfoot={
                  <>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        R${" "}
                        {formatCurrency(
                          stats.valor_registradas + stats.valor_canceladas ?? 0
                        )}
                      </td>
                      <td></td>
                    </tr>
                  </>
                }
                tableClass="table-nowrap mt-3 table-hover table-striped"
                onPageChange={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={"900px"}>
            <ModalHeader>
              Detalhes da Pule #{selectedPule?.idPule}-
              {selectedPule?.idPuleLottery}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box border="1px solid #ccc" p={2} borderRadius={4}>
                <Flex>
                  <Text fontWeight={"bold"}>Unidade: </Text>

                  <Text pl={2}>{selectedPule?.fullName.toUpperCase()}</Text>
                  <Spacer></Spacer>
                  <Text fontWeight={"bold"}>Valendo: </Text>
                  <Text pl={2}>
                    {getFormattedDate(selectedPule?.executionAt, false)}
                  </Text>
                </Flex>

                <Flex>
                  <Text fontWeight={"bold"}>Data/Hora: </Text>

                  <Text pl={2}>
                    {getFormattedDate(selectedPule?.createdAt, true)}
                  </Text>

                  <Spacer></Spacer>
                  <Text fontWeight={"bold"}>Loteria:</Text>
                  <Text pl={2}>{selectedPule?.title}</Text>
                </Flex>

                <Flex>
                  <Text fontWeight={"bold"}>Total Pule: </Text>
                  <Text pl={2}>
                    R${" "}
                    {selectedPule?.price_unit
                      ? formatCurrency(selectedPule.price_unit ?? 0)
                      : "0.00"}
                  </Text>
                  <Spacer></Spacer>
                  <Text fontWeight={"bold"}>Cotação:</Text>
                  <Text pl={2}>{selectedPule?.paymentTable?.name}</Text>
                </Flex>

                <Flex>
                  <Text fontWeight={"bold"}>Comissão: </Text>
                  <Text pl={2}>
                    {JSON.parse(selectedPule?.modalityComissions || "[]").map(
                      (comission: any) => {
                        return (
                          <Badge mr={1} border={"1px solid #444"}>
                            {" "}
                            {getNameOfModality(comission?.idModality)} -{" "}
                            {comission?.value}%
                          </Badge>
                        );
                      }
                    )}
                  </Text>
                </Flex>
              </Box>

              <TableContainer mt={2} border="1px solid #ccc">
                <ChakraTable size="sm" mt={2}>
                  <Thead>
                    <Tr>
                      <Th>Modalidade</Th>
                      <Th>Palpite</Th>
                      <Th>Posição</Th>
                      <Th isNumeric>Valor</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedPule?.predictions.map((item: any) =>
                      item.values.map((value: any) => {
                        return (
                          <Tr>
                            <Td>{item.modality.name}</Td>
                            <Td>{value.value}</Td>
                            <Td>{item.colocation}</Td>
                            <Td isNumeric>
                              R$ {formatCurrency(value.price ?? 0)}
                            </Td>
                          </Tr>
                        );
                      })
                    )}
                  </Tbody>
                </ChakraTable>
              </TableContainer>
            </ModalBody>

            <ModalFooter gap={2}>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Row>
    </React.Fragment>
  );
};

export default RepasseRegistradas;
