import classNames from "classnames";
import React, { useRef, useEffect, forwardRef, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";
import {
  FaPrint,
  FaFileExcel,
  FaFilePdf,
  FaArrowDown,
  FaArrowUp,
} from "react-icons/fa";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

// components
import Pagination from "./Pagination";
import { Flex, Spacer, Text, Button, Icon } from "@chakra-ui/react";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import LoaderTable from "./LoaderTable";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface GlobalFilterProps {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
  searchBoxClass: any;
  isSearchable: boolean;
  onPageChange: VoidFunction;
  paginate: any;
}

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchBoxClass,
  isSearchable,
  onPageChange,
  paginate,
}: GlobalFilterProps) => {
  const count = preGlobalFilteredRows?.length;
  const [value, setValue] = useState<any>(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    if (!isSearchable) {
      onPageChange && onPageChange({ search: value });
    }

    setGlobalFilter(value || undefined);
  }, 800);

  return (
    <div className={classNames(searchBoxClass)}>
      <span className="d-flex align-items-center">
        Pesquisar :{" "}
        <input
          type="search"
          value={value || ""}
          onChange={(e: any) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${
            count
              ? count + " registros..."
              : paginate?.total
              ? paginate?.total + " registros..."
              : ""
          }`}
          className="form-control w-auto ms-1"
        />
      </span>
    </div>
  );
};

interface IndeterminateCheckboxProps {
  indeterminate: any;
  children?: React.ReactNode;
}

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          ref={resolvedRef}
          {...rest}
        />
        <label htmlFor="form-check-input" className="form-check-label"></label>
      </div>
    </>
  );
});

interface TableProps {
  hideColumns?: string[];
  isCalculate?: boolean;
  calculateInput?: string;
  isSearchable?: boolean;
  hideSearch?: boolean;
  hideButtons?: boolean;
  isSortable?: boolean;
  pagination?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  isPrintable?: boolean;
  isExportableToExcel?: boolean;
  isExportableToPdf?: boolean;
  sizePerPageList?: {
    text: string;
    value: number;
  }[];
  columns: {
    Header: string;
    accessor: string;
    sort?: boolean;
    Cell?: any;
    className?: string;
  }[];
  data: any[];
  pageSize?: any;
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
  paginate?: any;
  onPageChange?: VoidFunction;
  tfoot: any;
}

const Table = (props: TableProps) => {
  const isSearchable = props["isSearchable"] || false;
  const hideSearch = props["hideSearch"] || false;
  const hideButtons = props["hideButtons"] || false;
  const isSortable = props["isSortable"] || false;
  const pagination = props["pagination"] || false;
  const isSelectable = props["isSelectable"] || false;
  const isExpandable = props["isExpandable"] || false;
  const sizePerPageList = props["sizePerPageList"] || [];
  const isCalculate = props["isCalculate"] || false;
  const calculateInput = props["calculateInput"] || "";
  const onPageChange = props["onPageChange"] || (((_: any) => {}) as any);
  const {
    isPrintable = true,
    isExportableToExcel = true,
    isExportableToPdf = true,
    paginate,
    allowHeader = true,
    tfoot = <></>,
    data,
    // outras props...
  } = props;

  const exportToPdf = () => {
    const doc = new jsPDF("l", "mm", "a4");
    autoTable(doc, { html: ".react-table" });
    doc.save("exportar.pdf");
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(props.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "exportar.xlsx");
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const tableHtml = document.querySelector(".react-table")?.outerHTML || "";

    printWindow?.document.write(`
      <html>
        <head>
          <title>Imprimir</title>
          <style>
            /* Add your print styles here */
          </style>
        </head>
        <body>
          ${tableHtml}
        </body>
      </html>
    `);

    printWindow?.document.close();
    printWindow?.print();
  };

  let otherProps: any = {};

  const [globalFilter, setGlobalFilter] = useState({});

  otherProps["globalFilter"] = globalFilter;
  otherProps["setGlobalFilter"] = setGlobalFilter;

  if (isSearchable) {
    otherProps["useGlobalFilter"] = useGlobalFilter;
  }
  if (isSortable) {
    otherProps["useSortBy"] = useSortBy;
  }
  if (isExpandable) {
    otherProps["useExpanded"] = useExpanded;
  }
  if (pagination) {
    otherProps["usePagination"] = usePagination;
  }
  if (isSelectable) {
    otherProps["useRowSelect"] = useRowSelect;
  }

  const dataTable = useTable(
    {
      columns: props["columns"],
      data: props["data"],
      initialState: { pageSize: props["pageSize"] || 100 },
    },
    otherProps.hasOwnProperty("useGlobalFilter") &&
      otherProps["useGlobalFilter"],
    otherProps.hasOwnProperty("useSortBy") && otherProps["useSortBy"],
    otherProps.hasOwnProperty("useExpanded") && otherProps["useExpanded"],
    otherProps.hasOwnProperty("usePagination") && otherProps["usePagination"],
    otherProps.hasOwnProperty("useRowSelect") && otherProps["useRowSelect"],
    (hooks) => {
      isSelectable &&
        hooks.visibleColumns.push((columns: any) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: any) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);

      isExpandable &&
        hooks.visibleColumns.push((columns: any) => [
          // Let's make a column for selection
          {
            // Build our expander column
            id: "expander", // Make sure it has an ID
            Header: ({
              getToggleAllRowsExpandedProps,
              isAllRowsExpanded,
            }: any) => (
              <span {...getToggleAllRowsExpandedProps()}>
                {isAllRowsExpanded ? "-" : "+"}
              </span>
            ),
            Cell: ({ row }) =>
              // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
              // to build the toggle for expanding a row
              row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps({
                    style: {
                      // We can even use the row.depth property
                      // and paddingLeft to indicate the depth
                      // of the row
                      paddingLeft: `${row.depth * 2}rem`,
                    },
                  })}
                >
                  {row.isExpanded ? "-" : "+"}
                </span>
              ) : null,
          },
          ...columns,
        ]);
    }
  );

  let rows = pagination ? dataTable.page : dataTable.rows;

  const totalValue = parseFloat(
    rows.reduce((a: any, b: any) => a + (b["original"][calculateInput] || 0), 0)
  ).toFixed(2);

  const searchInput = document.querySelector(
    "#wrapper > div.content-page > div.content > div > div:nth-child(2) > div > div.card > div > div.css-zzgb5p > div:nth-child(4) > span > input"
  );

  const searchValue = (searchInput as HTMLInputElement)?.value;

  const paginateChangeAction = ({ page }) => {
    onPageChange({
      search: searchValue,
      page: page,
      field: paginate?.sort?.field,
      order: paginate?.sort?.order,
    });
  };

  useEffect(() => {
    if (paginate) {
      const findColumn = dataTable.columns.find(
        (column: any) => column.id === paginate?.sort?.field
      );
      if (findColumn) {
        if (paginate?.sort?.order === "asc") {
          findColumn.toggleSortBy(false, false);
        } else {
          findColumn.toggleSortBy(true, false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginate]);

  const sortColumn = (column: any) => {
    if (column.sort && paginate) {
      onPageChange({
        order: !column.isSortedDesc ? "desc" : "asc",
        field: column.id,
        page: 1,
        search: searchValue,
      });
    }
    return;
  };

  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  return (
    <>
      {layout.loading && <LoaderTable />}

      {allowHeader && (
        <Flex
          gap={2}
          direction={{ base: "column", md: "row-reverse" }}
          alignItems={"flex-end"}
        >
          {isPrintable && !hideButtons && (
            <Button leftIcon={<Icon as={FaPrint} />} onClick={handlePrint}>
              Imprimir
            </Button>
          )}
          {isExportableToExcel && !hideButtons && (
            <Button
              leftIcon={<Icon as={FaFileExcel} />}
              onClick={exportToExcel}
            >
              Exportar para Excel
            </Button>
          )}
          {isExportableToPdf && !hideButtons && (
            <Button leftIcon={<Icon as={FaFilePdf} />} onClick={exportToPdf}>
              Exportar para PDF
            </Button>
          )}
          {isSearchable && !hideSearch && (
            <GlobalFilter
              paginate={paginate}
              onPageChange={onPageChange}
              isSearchable={isSearchable}
              preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
              globalFilter={dataTable.state.globalFilter}
              setGlobalFilter={dataTable.setGlobalFilter}
              searchBoxClass={props["searchBoxClass"]}
            />
          )}{" "}
          {!isSearchable && !hideSearch && (
            <GlobalFilter
              paginate={paginate}
              onPageChange={onPageChange}
              isSearchable={isSearchable}
              preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
              globalFilter={dataTable.state.globalFilter}
              setGlobalFilter={dataTable.setGlobalFilter}
              searchBoxClass={props["searchBoxClass"]}
            />
          )}
          <Spacer />
          {isCalculate && (
            <Text my={0}>
              <b>Total:</b> R$ {formatCurrency(Number(totalValue) || 0)}
            </Text>
          )}
        </Flex>
      )}

      <div className="table-responsive">
        <table
          {...dataTable.getTableProps()}
          className={classNames(
            "table table-centered react-table",
            props["tableClass"]
          )}
        >
          <thead className={props["theadClass"]}>
            {(dataTable.headerGroups || []).map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {(headerGroup.headers || []).map((column: any) => (
                  <>
                    {paginate ? (
                      <th
                        {...column.getHeaderProps(
                          column.sort && column.getSortByToggleProps()
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                          return sortColumn(column);
                        }}
                        className={classNames({
                          sorting_desc: column.isSortedDesc === true,
                          sorting_asc: column.isSortedDesc === false,
                          sortable: column.sort === true,
                        })}
                      >
                        <Flex
                          alignItems={"center"}
                          color={column.isSorted ? "blue.400" : ""}
                        >
                          <Flex>{column.render("Header")}</Flex>
                          <Spacer />
                          <Flex>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FaArrowDown />
                              ) : (
                                <FaArrowUp />
                              )
                            ) : (
                              "-"
                            )}
                          </Flex>
                        </Flex>
                      </th>
                    ) : (
                      <th
                        {...column.getHeaderProps(
                          column.sort && column.getSortByToggleProps()
                        )}
                        className={classNames({
                          sorting_desc: column.isSortedDesc === true,
                          sorting_asc: column.isSortedDesc === false,
                          sortable: column.sort === true,
                        })}
                      >
                        <Flex
                          alignItems={"center"}
                          color={column.isSorted ? "blue.400" : ""}
                        >
                          <Flex>{column.render("Header")}</Flex>
                          <Spacer />
                          <Flex>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FaArrowDown />
                              ) : (
                                <FaArrowUp />
                              )
                            ) : (
                              "-"
                            )}
                          </Flex>
                        </Flex>
                      </th>
                    )}
                  </>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...dataTable.getTableBodyProps()}>
            {(rows || []).map((row: any) => {
              dataTable.prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    backgroundColor:
                      row.original.disabled === 1 ? "#FAF0E6" : "", // Define o fundo vermelho quando disabled é 1
                  }}
                >
                  {row.cells.map((cell: any) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}

            {!rows?.length && (
              <tr>
                <td colSpan={20} className="text-center">
                  <b>Nada encontrado!</b>
                </td>
              </tr>
            )}
          </tbody>

          <tfoot className="tfoot">{tfoot ? tfoot : "<></>"}</tfoot>
        </table>
      </div>

      {pagination && (
        <>
          <Pagination
            tableProps={dataTable}
            sizePerPageList={sizePerPageList}
            paginate={paginate}
            paginateChangeAction={paginateChangeAction}
          />
        </>
      )}
    </>
  );
};

export default Table;
