import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Wizard, Steps, Step } from "react-albus";
import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, Button, ProgressBar } from "react-bootstrap";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import { useRegisterForm } from "../../../hooks";
import { useToast } from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import FormSelectInput from "../../../components/FormSelect";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Dispositivos",
  path: "/base/devices/",
  create: "/base/devices/new/",
  update: "/base/devices/update/",
  apiBase: "/bancas/devices/",
};

/**
 * INTERFACES
 */
interface FirstStepWizard {
  type: string;
  model: string;
  chip: string;
  idUnit: number;
  description: string;
  status: boolean;
}

/**
 * FORM VALIDATIONS
 */
const resolver = yupResolver(
  yup.object().shape({
    type: yup.string().required("Tipo é obrigatório"),
    model: yup.string().required("Modelo é obrigatório"),
    chip: yup.string().required("Chip é obrigatório"),
    idUnit: yup.string().required("ID da unidade é obrigatório"),
    description: yup.string().required("Descrição é obrigatório"),
    status: yup.boolean().required("Status é obrigatório"),
  })
);

const WizardForm = ({ idUpdate }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [dataSelect, setDataSelect] = useState<any>(null);

  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const firstWizard = useRegisterForm<FirstStepWizard>({ resolver });
  const toast = useToast();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoadUpdate = useCallback(async () => {
    dispatch(toggleLoader(true));
    await api.get(configs.apiBase + idUpdate, null).then((response) => {
      const data: any = response?.data ?? {};

      firstWizard.setValue("type", data?.type);
      firstWizard.setValue("model", data?.model);
      firstWizard.setValue("chip", data?.chip);
      firstWizard.setValue("description", data?.description);
      firstWizard.setValue("status", data?.status);
    });
    await api
      .get("/bancas/bff/select-data", null)
      .then((response) => {
        const data = response.data ?? [];
        setDataSelect(data);
        firstWizard.setValue("idUnit", data?.idUnit);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        dispatch(toggleLoader(false));

        navigate(configs.path);
      });
    dispatch(toggleLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUpdate]);

  const handlePageLoad = useCallback(async () => {
    if (idUpdate) handlePageLoadUpdate();
    await api.get("/bancas/bff/select-data", null).then((response) => {
      const data = response.data ?? [];
      setDataSelect(data);
      firstWizard.setValue("idUnit", data?.idUnit);
    });
  }, [idUpdate, handlePageLoadUpdate]);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  /**
   * AUXILIARS
   */
  const mountApiData = useCallback((): FirstStepWizard => {
    return {
      ...firstWizard.getValues(),
    };
  }, [firstWizard]);

  /**
   * ACTIONS
   */
  const handleSubmit = useCallback(async () => {
    if (!idUpdate) {
      return await api
        .create(configs.apiBase, mountApiData())
        .then(() => {
          dispatch(toggleLoader(false));
          navigate(configs.path);
          toast({
            title: "Registro adicionado com sucesso.",
            description: "O registro foi adicionado com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          dispatch(toggleLoader(false));
          // Exibe um Toast de erro
          toast({
            title: "Erro ao adicionar registro.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }

    await api
      .update(configs.apiBase + idUpdate, mountApiData())
      .then(() => {
        dispatch(toggleLoader(false));
        navigate(configs.path);
        toast({
          title: "Registro atualizado com sucesso.",
          description: "O registro foi atualizado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        dispatch(toggleLoader(false));
        toast({
          title: "Erro ao atualizar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [idUpdate, mountApiData, navigate]);

  return (
    <Card>
      <Card.Body>
        <h4 className="header-title mb-3">Informações</h4>

        <Wizard
          render={({ step, steps }) => (
            <React.Fragment>
              <ProgressBar
                animated
                striped
                variant="success"
                now={((steps.indexOf(step) + 1) / steps.length) * 100}
                className="mb-3 progress-sm"
              />

              <Steps>
                <Step
                  id="infos"
                  render={({ next }) => (
                    <form onSubmit={firstWizard.handleSubmit(next)}>
                      <FormSelectInput
                        name="type"
                        label="Selecione um tipo *"
                        multiple={false}
                        labelKey={"label"}
                        containerClass={"mb-3"}
                        resetField={firstWizard.resetField}
                        getValues={firstWizard.getValues}
                        setValue={firstWizard.setValue}
                        register={firstWizard.register}
                        errors={firstWizard.errors}
                        options={[
                          {
                            label: "POS",
                            value: "POS",
                          },
                          {
                            label: "MOBILE",
                            value: "MOBILE",
                          },
                        ]}
                      />
                      <FormSelectInput
                        name="idUnit"
                        label="Selecione uma unidade *"
                        multiple={false}
                        labelKey={"label"}
                        containerClass={"mb-3"}
                        resetField={firstWizard.resetField}
                        getValues={firstWizard.getValues}
                        setValue={firstWizard.setValue}
                        register={firstWizard.register}
                        errors={firstWizard.errors}
                        options={
                          dataSelect && dataSelect.collectors
                            ? dataSelect.collectors.map((item: any) => ({
                                label: item.fullName,
                                value: item.idUnit,
                              }))
                            : []
                        }
                      />
                      <FormInput
                        key="model"
                        type="text"
                        name="model"
                        label="Modelo *"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={firstWizard.register}
                        control={firstWizard.control}
                        errors={firstWizard.errors}
                      />
                      <FormInput
                        key="chip"
                        type="text"
                        name="chip"
                        label="Chip *"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={firstWizard.register}
                        control={firstWizard.control}
                        errors={firstWizard.errors}
                      />

                      <FormInput
                        key="description"
                        type="text"
                        name="description"
                        label="Descrição *"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={firstWizard.register}
                        control={firstWizard.control}
                        errors={firstWizard.errors}
                      />
                      <FormSelectInput
                        name="status"
                        label="Selecione um status *"
                        multiple={false}
                        labelKey={"label"}
                        containerClass={"mb-3"}
                        resetField={firstWizard.resetField}
                        getValues={firstWizard.getValues}
                        setValue={firstWizard.setValue}
                        register={firstWizard.register}
                        errors={firstWizard.errors}
                        options={[
                          {
                            label: "Ativada",
                            value: 1,
                          },
                          {
                            label: "Desativada",
                            value: 0,
                          },
                        ]}
                      />

                      <ul className="list-inline wizard mb-0">
                        <li className="next list-inline-item float-end">
                          <Button type="submit" variant="success">
                            Próximo
                          </Button>
                        </li>
                      </ul>
                    </form>
                  )}
                />

                <Step
                  id="confirmation"
                  render={({ previous }) => (
                    <Row>
                      <Col sm={12}>
                        <div className="text-center">
                          <h2 className="mt-0">
                            <i className="mdi mdi-check-all"></i>
                          </h2>
                          <h3 className="mt-0">Finalizado!</h3>

                          <p className="w-75 mb-2 mx-auto">
                            Deseja realmente prosseguir com a criação?
                          </p>
                        </div>
                      </Col>

                      <Col sm={12}>
                        <ul className="list-inline wizard mb-0">
                          <li className="previous list-inline-item">
                            <Button
                              type="submit"
                              variant="info"
                              onClick={previous}
                            >
                              Anterior
                            </Button>
                          </li>

                          <li className="next list-inline-item float-end">
                            <Button
                              type="submit"
                              variant="success"
                              onClick={handleSubmit}
                            >
                              Enviar
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  )}
                />
              </Steps>
            </React.Fragment>
          )}
        />
      </Card.Body>
    </Card>
  );
};

const DevicesCreate = () => {
  const { id } = useParams();
  const [idUpdate, setIdUpdate] = useState<string | undefined>(undefined);

  /**
   * LIFE CYCLE HELPER
   */
  const handlePageLoad = useCallback(() => {
    setIdUpdate(id);
  }, [id]);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  return (
    <React.Fragment>
      <PageTitle
        title={!idUpdate ? "Adicionar Registro" : "Atualizar Registro"}
        breadCrumbItems={[
          { label: configs.title, path: configs.path },
          {
            active: true,
            label: !idUpdate ? "Adicionar Registro" : "Atualizar Registro",
            path: !idUpdate ? configs.create : configs.update,
          },
        ]}
      />

      <Row>
        <Col lg={12}>
          <WizardForm idUpdate={idUpdate} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DevicesCreate;
