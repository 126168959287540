const sumTotalValue = (data: any[], field: string) => {
  let total = 0;
  if (data?.length > 0) {
    data.forEach((item: any) => {
      total += item[field];
    });
  }
  return total;
};

export { sumTotalValue };
