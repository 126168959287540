import React from "react";

// ROUTES
import AllRoutes from "./routes/Routes";

// OTHERS
import "./assets/scss/Icons.scss";
import "./assets/scss/Landing.scss";

// THEMES
import "./assets/scss/Default.scss";
// import './assets/scss/Saas.scss';
// import './assets/scss/Modern.scss';
// import "./assets/scss/Creative.scss";
// import './assets/scss/Purple.scss';
// import "./assets/scss/Material.scss";

const App = () => {
  return (
    <>
      <React.Fragment>
        <AllRoutes />
      </React.Fragment>
    </>
  );
};

export default App;
