import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Badge,
  Button,
  Box,
  Flex,
  Icon,
  Input,
  Select,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getActionsPermission } from "../../../helpers/api/auth";
import { FaPlus } from "react-icons/fa";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Limite de apostas por modalidade",
  path: "/base/limits/",
  create: "/base/limits/new/",
  apiBase: "/bancas/limits/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {new Date(props?.value).toLocaleDateString("pt-BR")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Limites" });
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  /**
   * HANDLERS
   */
  const handleClickDelete = async (id: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete(configs.apiBase + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      {actions.canDelete && (
        <Link
          to="#"
          className="action-icon"
          onClick={() => handleClickDelete(object?.idLimit)}
        >
          {" "}
          <i className="mdi mdi-delete"></i>
        </Link>
      )}
    </React.Fragment>
  );
};

const columns = [
  {
    Header: "ID",
    accessor: "idLimit",
    show: false,
    hidden: true,
  },
  {
    Header: "Cobrador",
    accessor: "unit.fullName",
    sort: true,
    Cell: ({ value }) => {
      return value ? value : "Todas as unidades";
    },
  },
  {
    Header: "Limites",
    accessor: "limits",
    sort: true,
    width: 500,
    Cell: ({ value }) => {
      const parsedValue = JSON.parse(value);
      return (
        <Box maxW={200}>
          {parsedValue.map((item, index) => {
            return (
              <span style={{ display: "block" }}>
                <b>{item.code}</b>: {item.limit}
              </span>
            );
          })}
        </Box>
      );
    },
  },
  {
    Header: "Atualizado em",
    accessor: "updatedAt",
    Cell: DateColumn,
    sort: true,
  },
  {
    Header: "Ações",
    accessor: "actions",
    sort: false,
    classes: "table-action",
    Cell: ActionColumn,
  },
];
/**
 * COMPONENT
 */
const Limits = () => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Limites" });
  const dispatch = useDispatch<AppDispatch>();
  const [limits, setLimits] = useState<any[]>([]);
  const [selectedUnit, setSelectedUnit] = useState("");

  const fields = [
    {
      name: "MILHAR",
      code: "M",
    },
    {
      name: "CENTENA",
      code: "C",
    },
    {
      name: "DEZENA",
      code: "D",
    },
    {
      name: "UNIDADE",
      code: "U",
    },
    {
      name: "DUQUE DEZ",
      code: "DD",
    },
    {
      name: "TERNO DEZ SECO",
      code: "TDS",
    },
    {
      name: "TERNO DEZ",
      code: "TD",
    },
    {
      name: "GRUPO",
      code: "G",
    },
    {
      name: "DUQUE GP",
      code: "DG",
    },
    {
      name: "TERNO GP",
      code: "TG",
    },
    {
      name: "QUADRA GP",
      code: "QAG",
    },
    {
      name: "QUINA GP",
      code: "QIG",
    },
    {
      name: "SENA GP 10/6",
      code: "SG",
    },
    {
      name: "PALPITÃO",
      code: "PP",
    },
    {
      name: "PASSE VAI",
      code: "PV",
    },
    { name: "PASSE VAI VEM", code: "PVV" },
    {
      name: "QUININHA",
      code: "QUI13",
    },
    {
      name: "SENINHA",
      code: "SEN14",
    },
    {
      name: "LOTINHA",
      code: "LOT16",
    },
  ];

  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useToast();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const { publish } = useEvent();
  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));

    await api.get(configs.apiBase, null)?.then((response) => {
      setData(response?.data ?? []);
    });
    await api
      .get("/bancas/bff/select-data", null)
      .then((response) => {
        const data = response.data ?? [];
        setDataSelect(data);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registros",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  }, [setData]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const postData = async () => {
    const removeEmptys = limits.filter((limit) => limit.limit);

    if (removeEmptys.length === 0) {
      toast({
        title: "Erro ao cadastrar limite",
        description: "Insira ao menos um limite",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const data = {
      idUnit: selectedUnit,
      limits: removeEmptys,
    };

    try {
      await api.create(configs.apiBase, data);
      publish("deleteItem", null);
      setLimits([]);
    } catch (e) {
      toast({
        title: "Erro ao cadastrar limites",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  };

  const handleUpdateLimit = (value: string, code: string) => {
    const newLimits = limits.filter((limit) => limit.code !== code);
    newLimits.push({
      code,
      limit: value,
    });
    setLimits(newLimits);
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col sm={4}>
          <Card>
            <Card.Body>
              <h4>Cadastrar Limites </h4>

              <Text>
                Defina a quantidade máxima de apostas por modalidade que serão
                aceitas para cada sorteio.
              </Text>
              <Select
                name="idUnit"
                defaultValue=""
                onChange={(e) => setSelectedUnit(e.target.value)}
                my={2}
              >
                <option value="">Todos os cobradores</option>
                {dataSelect && dataSelect.collectors
                  ? dataSelect.collectors.map((item: any) => (
                      <option key={item.idUnit} value={item.idUnit}>
                        {item.fullName}
                      </option>
                    ))
                  : null}
              </Select>

              <Card>
                <Card.Body
                  style={{
                    height: "400px",
                    overflowY: "auto",
                    border: "1px solid #eee",
                    borderRadius: 6,
                  }}
                >
                  <Flex direction={"column"}>
                    {fields.map((field) => (
                      <Flex
                        direction={"row"}
                        align={"center"}
                        justify={"center"}
                        borderBottom="1px solid #ccc"
                        py={2}
                      >
                        <b>{field.name}</b>
                        <Spacer />
                        <Input
                          type={"text"}
                          width={40}
                          value={
                            limits.find(
                              (limit: any) => limit.code === field.code
                            )?.limit ?? ""
                          }
                          onChange={(e) => {
                            const value = e.target.value.replace(
                              /[^\d.-]+/g,
                              ""
                            );
                            if (!isNaN(value)) {
                              handleUpdateLimit(value, field.code);
                            }
                          }}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </Card.Body>
              </Card>
              <Button
                colorScheme="yellow"
                leftIcon={<Icon as={FaPlus} />}
                onClick={postData}
              >
                Cadastrar limite
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={8}>
          <Card>
            <Card.Body>
              <h4>Limites Cadastrados </h4>
              <Table
                isSearchable={true}
                columns={columns}
                data={data}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-nowrap mt-3 table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Limits;
