import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";

// components
import HyperDatepicker from "../../../components/Datepicker";

import { Parser } from "@json2csv/plainjs";
import Select from "react-select";
import Statistics from "./Statistics";
import RevenueChart from "./RevenueChart";
import SalesAnalyticsChart from "./SalesAnalyticsChart";
import UsersBalances from "./UsersBalances";
import RevenueHistory from "./RevenueHistory";

import Table from "../../../components/Table";

import { balances, revenueHistory } from "./data";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { APICore } from "../../../helpers/api/apiCore";
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  Link,
  useToast,
} from "@chakra-ui/react";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { ViewIcon } from "@chakra-ui/icons";
import StatisticsWidget from "../../../components/StatisticsWidget";
import LoaderTable from "../../../components/LoaderTable";
import {
  MapContainer,
  TileLayer,
  Marker,
  CircleMarker,
  Tooltip as TipLeaf,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import FormSelect2Input from "../../../components/FormSelect2";

/**
 * HELPERS
 */
const api = new APICore();

const UnitsInactives = () => {
  const [selected, setSelected] = useState<any>({});
  const [extractions, setExtractions] = useState([]);
  const [units, setUnits] = useState([]);
  const [units2, setUnits2] = useState([]);
  const [days, setDays] = useState(30);
  const [collectors, setCollectors] = useState([]);
  const [listMap, setListMap] = useState([]);
  const [balance, setBalance] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputUnit, setSearchInputUnit] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCollector,
    onOpen: onOpenCollector,
    onClose: onCloseCollector,
  } = useDisclosure();

  const { permission: perm } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));

  const toast = useToast();

  const permission = perm?.title;

  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    try {
      api.get("/dashboard/inactive-life", null)?.then((response) => {
        setUnits(response?.data?.units);
        setCollectors(response?.data?.collectors || []);
      });

      await api.get("/dashboard/inactive", { days })?.then((response) => {
        setUnits2(response?.data);
      });
    } catch (e) {
      console.log(e);
    }

    dispatch(toggleLoader(false));
  }, [setExtractions]);

  const changeDays = async (day: number) => {
    dispatch(toggleLoader(true));
    setDays(day);
    await api.get("/dashboard/inactive", { day })?.then((response) => {
      setUnits2(response?.data);
    });
    dispatch(toggleLoader(false));
  };

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoading();
  }, [handlePageLoading]);

  const openModal = (extraction: any) => {
    setSelected(extraction);
    onOpen();
  };

  const permissionAllowed = [
    "Admin - Base",
    "Cobrador",
    "Cobrador - Básico",
    "Gerente",
    "Suporte",
    "Financeiro",
    "Gerente Região",
  ];
  const hasPermission = permissionAllowed.includes(permission);

  const findedExtractions = () => {
    const extractionsF = extractions.filter((item: any) =>
      item.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    return extractionsF;
  };

  const findedUnits = () => {
    const unitsF = units.filter(
      (item: any) =>
        String(item?.id)?.includes(searchInputUnit.toLowerCase()) ||
        item?.collector?.toLowerCase().includes(searchInputUnit.toLowerCase())
    );
    return unitsF;
  };

  const findedUnits2 = () => {
    const unitsF = units2.filter(
      (item: any) =>
        String(item?.id)?.includes(searchInputUnit.toLowerCase()) ||
        item?.collector?.toLowerCase().includes(searchInputUnit.toLowerCase())
    );
    return unitsF;
  };

  const download = () => {
    try {
      const parser = new Parser();
      const csv = parser.parse(findedUnits());

      // Convert CSV to HTML table
      const rows = csv.split("\n");
      rows.shift(); // remove the first line
      let html = `<body style="padding:0px; margin:0px;"><table width="100%" style="font-size:11px; text-transform:uppercase">`;
      html += `<tr> 
      <th style="padding:2px; text-align:left">INATIVOS</th>
      <th style="padding:2px; text-align:left">INATIVOS</th>
</tr>`;
      rows.forEach((row, i) => {
        if (i % 2 === 0) html += `<tr>`;
        const cells = row.split(",");
        html += `
<td  style="padding:2px; text-align:left">${cells[3]}<br/><b>(${cells[0]} - ${cells[2]})</b><br/>${cells[1]}<br/>${cells[4]}</td>
`;
        // cells.forEach((cell) => {
        //   html += "<td>" + cell + "</td>";
        // });
        if (i % 2 === 1) html += "</tr>";
      });
      html += "</table></body>";

      // Open a new window
      const printWindow = window.open("", "_blank");

      // Add the HTML to the new window
      printWindow.document.write(html);
    } catch (err) {
      console.error(err);
    }
  };

  const download2 = () => {
    try {
      const parser = new Parser();
      const csv = parser.parse(findedUnits2());

      // Convert CSV to HTML table
      const rows = csv.split("\n");
      rows.shift(); // remove the first line
      let html = `<body style="padding:0px; margin:0px;"><table width="100%" style="font-size:11px; text-transform:uppercase">`;
      html += `<tr> 
      <th style="padding:2px; text-align:left">INATIVOS</th>
      <th style="padding:2px; text-align:left">INATIVOS</th>
</tr>`;
      rows.forEach((row, i) => {
        if (i % 2 === 0) html += `<tr>`;
        const cells = row.split(",");
        html += `
<td  style="padding:2px; text-align:left">${cells[3]}<br/><b>(${cells[0]} - ${cells[2]})</b><br/>${cells[1]}<br/>${cells[4]}</td>
`;
        // cells.forEach((cell) => {
        //   html += "<td>" + cell + "</td>";
        // });
        if (i % 2 === 1) html += "</tr>";
      });
      html += "</table></body>";

      // Open a new window
      const printWindow = window.open("", "_blank");

      // Add the HTML to the new window
      printWindow.document.write(html);
    } catch (err) {
      console.error(err);
    }
  };

  const totalSell = findedExtractions()
    .filter((item: any) => item.extraction.status === "Prêmios pagos!")
    .reduce((acc: any, item: any) => acc + item.sell, 0);

  const totalAmount = findedExtractions()
    .filter((item: any) => item.extraction.status === "Prêmios pagos!")
    .reduce((acc: any, item: any) => acc + item.amount, 0);

  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const [selectedData, setSelectedData] = useState<any>({});
  const [selectedCollector, setSelectedCollector] = useState<any>(null);

  const handleOpenModal = (data) => {
    onOpenCollector();
    setSelectedData(data);
    setSelectedCollector({
      label: data.collector,
      value: data.idCollector,
    });
  };

  const handleSave = async () => {
    const payload = {
      idUnit: selectedData.id,
      idCollector: selectedCollector.value, // idCollector
    };

    dispatch(toggleLoader(true));

    try {
      await api
        .create("/dashboard/change-collector", payload)
        .then((response) => {
          toast({
            title: "Promotor alterado com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        });

      const oldData = units.find((item) => item.id === selectedData.id);
      const newData = {
        ...oldData,
        idCollector: selectedCollector.value,
        collector: selectedCollector.label,
      };
      const index = units.findIndex((item) => item.id === selectedData.id);
      units[index] = newData;
      setUnits([...units]);
      onCloseCollector();
    } catch (e) {
      toast({
        title: "Erro ao salvar unidade.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
      classes: "fw-semibold",
    },
    {
      Header: "Nome",
      accessor: "unidade",
      sort: false,
    },
    {
      Header: "Whatsapp",
      accessor: "whatsapp",
      sort: false,
      Cell: ({ value }) => (
        <Tooltip label="Chamar cliente">
          <a
            href={`https://wa.me/+55${value.replace(
              /\D/g,
              ""
            )}?text=${encodeURI("Olá, tudo bem?")}`}
            target="_blank"
            rel="noreferrer"
          >
            <Flex gap={4}>
              <span>{value}</span>
              <Image src="/whatsapp.png" width="24px" />
            </Flex>
          </a>
        </Tooltip>
      ),
    },
    {
      Header: "PROMOTOR",
      accessor: "collector",
      sort: true,
      Cell: ({ value, row }) => {
        const data = row?.original;
        return (
          <>
            {collectors.length > 0 ? (
              <>
                <Link color="blue" onClick={() => handleOpenModal(data)}>
                  {value}
                </Link>
              </>
            ) : (
              <>{value}</>
            )}
          </>
        );
      },
    },
    {
      Header: "ULTIMA RECARGA",
      accessor: "ultima_recarga",
      sort: true,
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Retaguarda</h4>
          </div>
        </Col>
      </Row>

      <Modal isOpen={isOpenCollector} onClose={onCloseCollector}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Trocar promotor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Text mr={2} my={0} fontWeight={"bold"}>
                UNIDADE:
              </Text>
              <Text my={0}>{selectedData.id}</Text>
            </Flex>

            <Flex>
              <Text mr={2} my={0} fontWeight={"bold"}>
                Nome:
              </Text>
              <Text my={0}>{selectedData.unidade}</Text>
            </Flex>

            <Flex>
              <Text mr={2} my={0} fontWeight={"bold"}>
                Whatsapp:
              </Text>
              <Text my={0}>{selectedData.whatsapp}</Text>
            </Flex>

            <Divider my={0} />

            <Flex mt={1}>
              <Text mr={2} fontWeight={"bold"}>
                PROMOTOR:{" "}
              </Text>

              <Text as="span">
                {selectedData.collector} - ({selectedData.idCollector})
              </Text>
            </Flex>

            <Flex>
              <Text mr={2} fontWeight={"bold"}>
                TROCAR PROMOTOR:{" "}
              </Text>
            </Flex>

            <Box>
              <Select
                classNamePrefix="select"
                defaultValue={selectedCollector}
                isClearable={false}
                isSearchable={true}
                options={
                  collectors
                    ? collectors.map((item: any) => ({
                        label: item.fullName + `(${item.idCollector})`,
                        value: item.idCollector,
                      }))
                    : []
                }
                placeholder="Selecione..."
                onChange={(e) => {
                  setSelectedCollector(e);
                }}
                id={`formChangeCollector`}
                value={selectedCollector}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={() => handleSave()}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {hasPermission && (
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Flex justifyContent={"right"}>
                  <Button onClick={download}>Imprimir</Button>
                </Flex>

                <Flex flexDirection={"column"} width={"100%"}>
                  <Text fontWeight={"bold"}>
                    Unidades sem depósito ({findedUnits()?.length || 0}):
                  </Text>
                  <Input
                    value={searchInputUnit}
                    onChange={(e) => setSearchInputUnit(e.target.value)}
                    placeholder="Filtrar unidades"
                    size="sm"
                    mx={2}
                    mb={2}
                  />

                  <Table
                    columns={columns}
                    data={findedUnits() || []}
                    pageSize={5}
                    isSortable={true}
                    pagination={true}
                    allowHeader={false}
                    tableClass="table-nowrap table-striped"
                  />
                </Flex>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Flex justifyContent={"right"} gap={1}>
                  <Button
                    onClick={() => changeDays(15)}
                    bg={days === 15 ? "yellow" : "white"}
                    border={"1px solid #ccc"}
                  >
                    15D
                  </Button>
                  <Button
                    onClick={() => changeDays(30)}
                    bg={days === 30 ? "yellow" : "white"}
                    border={"1px solid #ccc"}
                  >
                    30D
                  </Button>
                  <Button onClick={download2}>Imprimir</Button>
                </Flex>

                <Flex flexDirection={"column"} width={"100%"}>
                  <Text fontWeight={"bold"}>
                    Sem recarregar ({days}d) ({findedUnits2()?.length || 0}):
                  </Text>
                  <Input
                    value={searchInputUnit}
                    onChange={(e) => setSearchInputUnit(e.target.value)}
                    placeholder="Filtrar unidades"
                    size="sm"
                    mx={2}
                    mb={2}
                  />

                  <Table
                    columns={columns}
                    data={findedUnits2() || []}
                    pageSize={5}
                    isSortable={true}
                    pagination={true}
                    allowHeader={false}
                    tableClass="table-nowrap table-striped"
                  />
                </Flex>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default UnitsInactives;
