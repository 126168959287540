import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  Box,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  Divider,
  Flex,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  StackDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Icon,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
import { format } from "date-fns";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getActionsPermission } from "../../../helpers/api/auth";
import { FaPlus, FaRetweet } from "react-icons/fa";
/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Financeiro",
  path: "/base/financial/",
  update: "/base/financial/update/",
  apiBase: "/bancas/adjusts/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {props?.value && format(new Date(props?.value), "dd/MM/yyyy HH:mm")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumnBalanceUnits = ({ ...props }: any) => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Financeiro" });
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  const [selectedRowData, setSelectedRowData] = useState<any>(object);
  const [description, setDescription] = useState("");
  const [transaction, setTransaction] = useState("CREDIT");
  const [account, setAccount] = useState("JB");
  const [type, setType] = useState("BONUS");
  const [value, setValue] = useState<string>("");

  const clearStates = () => {
    setDescription("");
    setValue("");
  };

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();

  /**
   * HANDLERS
   */

  const handleSave = async () => {
    if (!window.confirm("Deseja realmente creditar o jogador?")) {
      return;
    }
    dispatch(toggleLoader(true));
    const payload = {
      idUnit: selectedRowData.id,
      account,
      description,
      value: parseCurrency(value),
      type,
      transaction,
    };
    await api
      .create("bancas/financial/ajuste", payload)
      .then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro salvo.",
          description: "O registro foi salvo com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao salvar o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
    onClose();
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    clearStates();
  }, [isOpen]);

  const Modal3 = () => {
    return (
      <>
        {actions.canWrite && (
          <ChakraButton
            onClick={onOpen}
            colorScheme="teal"
            backgroundColor="gray.200"
            color="gray"
            size="sm"
            borderRadius="0"
          >
            C/D
          </ChakraButton>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crédito/Débito da Unidade</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={2}>{selectedRowData?.fullname?.toUpperCase()}</Text>

            <FormControl id="type" mt={4}>
              <FormLabel>Conta</FormLabel>
              <Select>
                {/* <option value="debito">Débito</option> */}
                <option value="JB">JB</option>
                {/* <option value="bonus">Bônus</option> */}
              </Select>
            </FormControl>

            <FormControl id="type" mt={4}>
              <FormLabel>Transação</FormLabel>
              <Select>
                {/* <option value="debito">Débito</option> */}
                <option value="CREDIT">Crédito</option>
                {/* <option value="bonus">Bônus</option> */}
              </Select>
            </FormControl>

            <FormControl id="type" mt={4}>
              <FormLabel>Origem</FormLabel>
              <Select>
                {/* <option value="debito">Débito</option> */}
                <option value="BONUS">Bônus</option>
                {/* <option value="bonus">Bônus</option> */}
              </Select>
            </FormControl>

            <FormControl id="description" mt={4}>
              <FormLabel>Descrição</FormLabel>
              <Input
                type="text"
                placeholder="Descrição"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl id="value" mt={4}>
              <FormLabel>Valor R$</FormLabel>
              <Input
                type="text"
                placeholder="R$ 0,00"
                value={value}
                onChange={(e: any) => {
                  setValue(formatCurrency(parseCurrency(e.target.value)));
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={handleSave}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal3 />
    </React.Fragment>
  );
};

const ActionColumnDelete = ({ ...props }: any) => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Financeiro" });
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();

  /**
   * HANDLERS
   */
  const handleClickDelete = async (id: string) => {
    if (window.confirm("Deseja realmente reverter este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete("/bancas/financial/" + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };
  return (
    <React.Fragment>
      {actions.canDelete && object?.operation === "MANUAL" && (
        <Link
          to="#"
          className="action-icon"
          style={{ fontSize: 24 }}
          onClick={() => handleClickDelete(object?.id)}
        >
          {" "}
          <i className="mdi mdi-cash-refund" style={{ color: "#c0392b" }}></i>
        </Link>
      )}
    </React.Fragment>
  );
};

const columnsBalanceUnits = [
  {
    Header: "Data",
    accessor: "createdAt",
    sort: false,
    Cell: (props) => new Date(props.value).toLocaleDateString("pt-BR"),
  },
  {
    id: "idUnit",
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    id: "fullName",
    Header: "Nome",
    accessor: "fullname",
    sort: true,
    Cell: ({ value }) => (
      <span style={{ textTransform: "uppercase" }}>{value}</span>
    ),
  },
  {
    Header: "Limite Disponível",
    accessor: "availableLimit",
    sort: false,
    Cell: ({ value }) => "R$ " + formatCurrency(value),
  },
  {
    Header: "Saldo Bloqueado",
    accessor: "blockedBalance",
    sort: false,
    Cell: ({ value }) => "R$ " + formatCurrency(value),
  },
  {
    id: "sell",
    Header: "Venda",
    accessor: "sell",
    sort: true,
    Cell: ({ value }) => "R$ " + formatCurrency(value),
  },
  {
    id: "comission",
    Header: "Comissão",
    accessor: "comission",
    sort: true,
    Cell: ({ value }) => {
      return (
        <span style={{ color: "#c0392b" }}>R$ {formatCurrency(value)}</span>
      );
    },
  },
  {
    id: "awards",
    Header: "Prêmios",
    accessor: "awards",
    sort: true,
    Cell: ({ value }) => {
      return (
        <span style={{ color: "#c0392b" }}>R$ {formatCurrency(value)}</span>
      );
    },
  },
  {
    id: "credits",
    Header: "Créditos",
    accessor: "credits",
    sort: true,
    Cell: ({ value }) => {
      return (
        <span style={{ color: "#c0392b" }}>R$ {formatCurrency(value)}</span>
      );
    },
  },
  {
    id: "debits",
    Header: "Débitos",
    accessor: "debits",
    sort: true,
    Cell: ({ value }) => "R$ " + formatCurrency(value),
  },
  {
    id: "deposit",
    Header: "Mandou",
    accessor: "mandou",
    sort: true,
    Cell: ({ value }) => "R$ " + formatCurrency(value),
  },
  {
    id: "withdraw",
    Header: "Recebeu",
    accessor: "recebeu",
    sort: true,
    Cell: ({ value }) => "R$ " + formatCurrency(value),
  },
  {
    Header: "Saldo Anterior",
    accessor: "previousBalance",
    sort: false,
    Cell: ({ value }) => {
      const negativo = value < 0;
      const novoValor = value * -1;
      return (
        <span style={{ color: negativo ? "green" : "#c0392b" }}>
          R$ {formatCurrency(novoValor)}
        </span>
      );
    },
  },
  {
    Header: "Saldo",
    accessor: "balance",
    sort: false,
    Cell: ({ value }) => {
      const negativo = value < 0;
      const novoValor = value * -1;
      return (
        <span style={{ color: negativo ? "green" : "#c0392b" }}>
          R$ {formatCurrency(novoValor)}
        </span>
      );
    },
  },
  {
    Header: "Ações",
    accessor: "actions",
    sort: false,
    classes: "table-action",
    Cell: ActionColumnBalanceUnits,
  },
];

const columnsFutureBalance = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Data",
    accessor: "createdAt",
    sort: true,
    Cell: DateColumn,
  },
  {
    Header: "ID da Unidade",
    accessor: "idUnit",
    sort: false,
  },
  {
    id: "fullName",
    Header: "Nome",
    accessor: "fullname",
    sort: false,
    Cell: ({ value }) => (
      <span style={{ textTransform: "uppercase" }}>{value}</span>
    ),
  },

  {
    Header: "Descrição",
    accessor: "description",
    sort: false,
  },
  {
    Header: "Operação",
    accessor: "operation",
    sort: false,
    Cell: ({ row, value }) => {
      const operacao = value;
      const transacao = row.original.transaction;

      const OperationEnum = {
        COMISSION: "COMISSÃO",
        DEPOSIT: "MANDOU", // MANDOU
        WITHDRAW: "SAQUE", // RECEBEU
        AWARD: "PULE PREMIADA",
        BET: "APOSTA",
        REVERSAL: "ESTORNO",
        MANUAL: "AJUSTE F.",
      };

      const TransactionEnum = {
        CREDIT: "CRÉDITO",
        DEBIT: "DÉBITO",
      };

      let colorScheme2;

      switch (TransactionEnum[transacao]) {
        case "CRÉDITO":
          colorScheme2 = "red";
          break;
        case "DÉBITO":
          colorScheme2 = "green";
          break;
        default:
          colorScheme = "gray";
      }

      let colorScheme;

      switch (OperationEnum[operacao]) {
        case "MANUAL":
          colorScheme = "blue";
          break;
        case "COMISSÃO":
          colorScheme = "red";
          break;
        case "MANDOU":
          colorScheme = "green";
          break;
        case "SAQUE":
          colorScheme = "red";
          break;
        case "PULE PREMIADA":
          colorScheme = "red";
          break;
        case "APOSTA":
          colorScheme = "green";
          break;
        case "ESTORNO":
          colorScheme = "blue";
          break;
        default:
          colorScheme = "gray";
      }
      return (
        <>
          <Badge colorScheme={colorScheme2}>{TransactionEnum[transacao]}</Badge>{" "}
          <br />
          <Badge colorScheme={colorScheme}>{OperationEnum[operacao]}</Badge>
        </>
      );
    },
  },
  {
    Header: "Conta Origem",
    accessor: "type",
    sort: false,
    Cell: ({ value }) => {
      let colorScheme;
      let type;
      switch (value) {
        case "BONUS":
          colorScheme = "green";
          type = "Bônus";
          break;
        case "PREMIO":
          colorScheme = "red";
          type = "Prêmio";
          break;
        case "SALDO":
          colorScheme = "blue";
          type = "Saldo/Recarga";
          break;
        default:
          colorScheme = "gray";
          type = value;
      }
      return <Badge colorScheme={colorScheme}>{type}</Badge>;
    },
  },
  {
    Header: "Valor",
    accessor: "value",
    sort: true,
    classes: "fw-semibold",
    Cell: ({ value }) => {
      const formattedValue = formatCurrency(value);
      return <span>R$ {formattedValue}</span>;
    },
  },
  {
    Header: "Ações",
    accessor: "actions",
    sort: false,
    classes: "table-action",
    Cell: ActionColumnDelete,
  },
];

/**
 * COMPONENT
 */
const Adjusts = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);

  const toast = useToast();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const [formOperations, setFormOperations] = useState<string>("");
  const [formTransactions, setFormTransactions] = useState<string>("");
  const [formUnit, setFormUnit] = useState<string>("");
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    dispatch(toggleLoader(true));
    try {
      let dates = {
        startDate: formStartDate,
        endDate: formEndDate,
      };
      let payload = {
        ...dates,
        unit: formUnit,
      };

      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
      await api.get("/bancas/bff/select-data", null).then((response) => {
        setDataSelect(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const preparePayload = (type: string) => {
    let dates = {
      startDate: formStartDate,
      endDate: formEndDate,
    };
    let payload = {
      ...dates,
      transactions: formTransactions,
      operations: formOperations,
      unit: formUnit,
    };
    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };
    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    } else if (type === "7d") {
      payload = {
        ...payload,
        startDate: decreaseDate(7),
        endDate: hoje,
      };
    } else if (type === "30d") {
      payload = {
        ...payload,
        startDate: decreaseDate(30),
        endDate: hoje,
      };
    } else if (type === "mes") {
      payload = {
        ...payload,
        startDate: hoje.split("-")[0] + "-" + hoje.split("-")[1] + "-01",
        endDate: hoje,
      };
    }
    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);
    return payload;
  };

  const handleSearch = async (type, moreData) => {
    const payload = preparePayload(type);
    dispatch(toggleLoader(true));
    try {
      await api
        .create(configs.apiBase, {
          ...payload,
          ...moreData,
          page: moreData.page ? moreData.page : 1,
        })
        .then((response) => {
          setData(response.data ?? []);
        });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const handlePageChange = async ({ page, field, order, search }) => {
    return await handleSearch("default", { page, field, order, search });
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4>Lançamentos financeiros</h4>
              <Flex direction={["column", "row"]} wrap="wrap" gap={2} my={2}>
                <ChakraButton
                  colorScheme="orange"
                  onClick={() => handleSearch("ontem", {})}
                >
                  Ontem
                </ChakraButton>
                <ChakraButton
                  colorScheme="yellow"
                  onClick={() => handleSearch("hoje", {})}
                >
                  Hoje
                </ChakraButton>
                <ChakraButton
                  colorScheme="teal"
                  onClick={() => handleSearch("7d", {})}
                >
                  7D
                </ChakraButton>
                <ChakraButton
                  colorScheme="blue"
                  onClick={() => handleSearch("30d", {})}
                >
                  30D
                </ChakraButton>
                <ChakraButton
                  colorScheme="red"
                  onClick={() => handleSearch("mes", {})}
                >
                  Mês
                </ChakraButton>
              </Flex>

              <Row>
                <Col sm={6}>
                  <Text fontWeight={"bold"} my={2}>
                    Unidade:
                  </Text>
                  <Select
                    placeholder="Todas as unidades"
                    value={formUnit}
                    onChange={(e) => setFormUnit(e.target.value)}
                  >
                    <optgroup label={"Regiões"}>
                      {dataSelect?.regions.map((item: any) => (
                        <option value={"R:" + item.idRegion}>
                          {item.name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label={"Cobradores"}>
                      {dataSelect?.collectors.map((item: any) => (
                        <option value={"P:" + item.idUnit}>
                          {item.fullName}
                        </option>
                      ))}
                    </optgroup>
                  </Select>
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Data inicial:
                  </Text>
                  <Input
                    placeholder="Data início"
                    size="md"
                    type="date"
                    value={formStartDate}
                    onChange={(e) => setFormStartDate(e.target.value)}
                  />
                </Col>

                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Data Final:
                  </Text>
                  <Input
                    placeholder="Data fim"
                    size="md"
                    type="date"
                    value={formEndDate}
                    onChange={(e) => setFormEndDate(e.target.value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={3} className="mb-2">
                  <Text fontWeight={"bold"} my={2}>
                    Transação:
                  </Text>
                  <Select
                    placeholder="Todas transações"
                    value={formTransactions}
                    onChange={(e) => setFormTransactions(e.target.value)}
                  >
                    <option value="CREDIT">Crédito</option>
                    <option value="DEBIT">Débito</option>
                  </Select>
                </Col>
                <Col sm={3} className="mb-2">
                  <Text fontWeight={"bold"} my={2}>
                    Operação:
                  </Text>
                  <Select
                    placeholder="Todas operações"
                    value={formOperations}
                    onChange={(e) => setFormOperations(e.target.value)}
                  >
                    <option value="DEPOSIT">MANDOU</option>
                    <option value="WITHDRAW">SAQUE</option>
                    <option value="BET">APOSTA</option>
                    <option value="AWARD">PULE PREMIADA</option>
                    <option value="REVERSAL">ESTORNOS</option>
                    <option value="COMISSION">COMISSÃO</option>
                    <option value="MANUAL">AJUSTE F.</option>
                  </Select>
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch("default", {})}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>
              <Table
                isSearchable={false}
                columns={columnsFutureBalance}
                data={data.lancamentos ?? []}
                useSortBy={true}
                paginate={data?.paginate || {}}
                isSortable={true}
                pagination={true}
                onPageChange={handlePageChange}
                tableClass="table-nowrap mt-3 table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Adjusts;
