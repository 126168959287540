import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TableContainer,
  Text,
  useDisclosure,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  useToast,
  Divider,
  Input,
  Spacer,
  Select,
  Box,
} from "@chakra-ui/react";
import { CheckIcon, EditIcon, RepeatClockIcon } from "@chakra-ui/icons";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import useEvent from "../../../hooks/useEvent";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import LoaderTable from "../../../components/LoaderTable";
import LoaderFull from "../../../components/LoaderFull";
import { format, parseISO } from "date-fns";
import StatisticsWidget from "../../../components/StatisticsWidget";

/**
 * HELPERS
 */
const api = new APICore();

/**
 * COMPONENT
 */
const Invoices = () => {
  /**
   * HOOKS
   */
  const [data, setData] = useState([]);
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );

  const { permission: perm } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));

  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const handleSearch = async () => {
    dispatch(toggleLoader(true));
    try {
      const payload = {
        startDate: formStartDate,
        endDate: formEndDate,
      };
      await api.create("/system/invoices", payload).then((response) => {
        setData(response.data ?? []);
      });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Erro ao buscar faturamento",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const sumTotal = () => {
    let sell = 0;
    let invoice = 0;
    let cashout = {
      total: 0,
      qtd: 0,
      return: 0,
    };
    let cashin = {
      total: 0,
      qtd: 0,
      return: 0,
    };
    data.forEach((base) => {
      sell += base.sell;
      invoice += base.invoice;
      cashout.total += base.cashout.total;
      cashout.qtd += base.cashout.qtd;
      cashout.return += base.cashout.return;
      cashin.total += base.cashin.total;
      cashin.qtd += base.cashin.qtd;
      cashin.return += base.cashin.return;
    });
    return { sell, invoice, cashout, cashin };
  };

  return (
    <React.Fragment>
      {layout.loading && <LoaderFull />}

      <PageTitle
        breadCrumbItems={[
          { label: "Faturamento", path: "/invoices", active: true },
        ]}
        title={"Faturamento"}
      />

      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Box mb={4}>
                <Row>
                  <Col sm={6}>
                    <Text fontWeight={"bold"} my={2}>
                      Data inicial:
                    </Text>
                    <Input
                      placeholder="Data início"
                      size="md"
                      type="date"
                      value={formStartDate}
                      onChange={(e) => setFormStartDate(e.target.value)}
                    />
                  </Col>

                  <Col sm={6}>
                    <Text fontWeight={"bold"} my={2}>
                      Data Final:
                    </Text>
                    <Input
                      placeholder="Data fim"
                      size="md"
                      type="date"
                      value={formEndDate}
                      onChange={(e) => setFormEndDate(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col>
                    <Button
                      variant="primary"
                      className="waves-effect waves-light"
                      onClick={handleSearch}
                    >
                      <i className="fas fa-search me-1"></i> Buscar
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {data.length > 0 && (
        <>
          <Row>
            <Col sm={6}>
              <StatisticsWidget
                variant="info"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimals: 2,
                  decimal: ",",
                  separator: ".",
                }}
                description="VENDAS"
                stats={sumTotal()?.invoice}
                icon="fe-dollar-sign"
              />
            </Col>
            <Col sm={6}>
              <StatisticsWidget
                variant="info"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimals: 2,
                  decimal: ",",
                  separator: ".",
                }}
                description="RETORNO PIX"
                stats={
                  (sumTotal()?.cashout?.return || 0) +
                  (sumTotal()?.cashin?.return || 0)
                }
                icon="fe-shuffle"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <StatisticsWidget
                variant="success"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimals: 2,
                  decimal: ",",
                  separator: ".",
                }}
                description="FATURAMENTO"
                stats={
                  sumTotal()?.invoice +
                  (sumTotal()?.cashout?.return || 0) +
                  (sumTotal()?.cashin?.return || 0)
                }
                icon="fe-dollar-sign"
              />
            </Col>
            <Col sm={6}>
              <StatisticsWidget
                variant="success"
                description="CLIENTES"
                stats={data.length}
                icon="fe-user"
              />
            </Col>
          </Row>

          <Flex
            mb={4}
            p={2}
            bg="white"
            flexDirection={{ base: "column", md: "row" }}
            border="1px solid #c1c1c1"
            borderTopRadius={12}
          >
            <Flex flexDirection={"column"} width={"100%"} className="no-print">
              <TableContainer>
                <Text ml={4} mt={2} fontWeight={"bold"}>
                  FATURAMENTO DE VENDAS
                </Text>
                <ChakraTable size="sm" border="1px solid #c3c3c3">
                  <Thead>
                    <Tr>
                      <Th bgColor={"#f4f4f4"}>BANCAS</Th>
                      <Th textAlign={"center"} colSpan={3} bgColor={"#bedaef"}>
                        Vendas (JB/FARM)
                      </Th>

                      <Th textAlign={"center"} colSpan={3} bgColor={"#b4d3a7"}>
                        Entradas <br />
                        (R$20,00 a R$49,99 - R$0,10)
                      </Th>
                      <Th textAlign={"center"} colSpan={3} bgColor={"#f4cccc"}>
                        Saídas <br />
                        (0,15%)
                      </Th>
                    </Tr>
                  </Thead>
                  <Thead>
                    <Tr>
                      <Th bgColor={"#f4f4f4"}>#</Th>
                      <Th bgColor={"#bedaef"}>JB/FARM</Th>
                      <Th bgColor={"#bedaef"}>ROYALTS</Th>
                      <Th bgColor={"#bedaef"}>FATURAMENTO</Th>
                      {/* ENTRADAS */}
                      <Th bgColor={"#b4d3a7"}>QTD</Th>
                      <Th bgColor={"#b4d3a7"}>VALOR</Th>
                      <Th bgColor={"#b4d3a7"}>RETORNO</Th>
                      {/* SAIDAS */}
                      <Th bgColor={"#f4cccc"}>QTD</Th>
                      <Th bgColor={"#f4cccc"}>VALOR</Th>
                      <Th bgColor={"#f4cccc"}>RETORNO</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.map((base) => (
                      <Tr color={base?.sell <= 1 ? "#ff0000" : "#000000"}>
                        <Td
                          bgColor={"#f4f4f4"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          {base?.title} <br />
                          {base?.start &&
                            format(parseISO(base?.start), "dd/MM/yyyy")}
                        </Td>
                        <Td
                          bgColor={"#e9f2f9"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          R$ {formatCurrency(base?.sell || 0)} <br />
                          R$ {formatCurrency(base?.farms || 0)}
                        </Td>
                        <Td
                          bgColor={"#e9f2f9"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          {base?.royalts}%
                        </Td>
                        <Td
                          bgColor={"#bedaef"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          R$ {formatCurrency(base?.invoice || 0)}
                        </Td>

                        {/* ENTRADAS */}
                        <Td
                          bgColor={"#e1eddb"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          {base?.cashin?.qtd || 0}
                        </Td>
                        <Td
                          bgColor={"#e1eddb"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          R$ {formatCurrency(base?.cashin?.total || 0)}
                        </Td>
                        <Td
                          bgColor={"#b4d3a7"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          R$ {formatCurrency(base?.cashin?.return || 0)}
                        </Td>

                        {/* SAIDAS */}
                        <Td
                          bgColor={"#f9e5e5"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          {base?.cashout?.qtd || 0}
                        </Td>
                        <Td
                          bgColor={"#f9e5e5"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          R$ {formatCurrency(base?.cashout?.total || 0)}
                        </Td>
                        <Td
                          bgColor={"#f4cccc"}
                          borderBottom="1px solid #c3c3c3"
                        >
                          R$ {formatCurrency(base?.cashout?.return || 0)}
                        </Td>
                      </Tr>
                    ))}

                    <Tr>
                      <Th bgColor={"#f4f4f4"}></Th>
                      <Th bgColor={"#e9f2f9"}></Th>
                      <Th bgColor={"#e9f2f9"}></Th>
                      <Th bgColor={"#bedaef"}>
                        R$ {formatCurrency(sumTotal().invoice || 0)}
                      </Th>
                      {/* ENTRADAS */}
                      <Th bgColor={"#e1eddb"}>{sumTotal().cashin.qtd || 0}</Th>
                      <Th bgColor={"#e1eddb"}>
                        R$ {formatCurrency(sumTotal().cashin.total || 0)}
                      </Th>
                      <Th bgColor={"#b4d3a7"}>
                        R$ {formatCurrency(sumTotal().cashin.return || 0)}
                      </Th>
                      {/* SAIDAS */}
                      <Th bgColor={"#f9e5e5"}>{sumTotal().cashout.qtd || 0}</Th>
                      <Th bgColor={"#f9e5e5"}>
                        R$ {formatCurrency(sumTotal().cashout.total || 0)}
                      </Th>
                      <Th bgColor={"#f4cccc"}>
                        R$ {formatCurrency(sumTotal().cashout.return || 0)}
                      </Th>
                    </Tr>
                  </Tbody>
                </ChakraTable>
              </TableContainer>
            </Flex>
          </Flex>
        </>
      )}
    </React.Fragment>
  );
};

export default Invoices;
