import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Icon,
  Input,
  Badge,
  Select,
  TableContainer,
  Tbody,
  Td,
  Text,
  Table,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { FaPlus } from "react-icons/fa";
import { format } from "date-fns";
import { sumTotalValue } from "../../../helpers/sum";
import LoaderTable from "../../../components/LoaderTable";

const colocacoes = [
  "1 PRÊMIO",
  "2 PRÊMIO",
  "3 PRÊMIO",
  "4 PRÊMIO",
  "5 PRÊMIO",
  "6 PRÊMIO",
  "7 PRÊMIO",
  "8 PRÊMIO",
  "9 PRÊMIO",
  "10 PRÊMIO",
  "1/2 PRÊMIO",
  "1/3 PRÊMIO",
  "1/4 PRÊMIO",
  "1/5 PRÊMIO",
  "1/6 PRÊMIO",
  "1/7 PRÊMIO",
  "1/8 PRÊMIO",
  "1/9 PRÊMIO",
  "1/10 PRÊMIO",
  "2/3 PRÊMIO",
  "2/4 PRÊMIO",
  "2/5 PRÊMIO",
  "2/6 PRÊMIO",
  "2/7 PRÊMIO",
  "2/8 PRÊMIO",
  "2/9 PRÊMIO",
  "2/10 PRÊMIO",
  "3/4 PRÊMIO",
  "3/5 PRÊMIO",
  "3/6 PRÊMIO",
  "3/7 PRÊMIO",
  "3/8 PRÊMIO",
  "3/9 PRÊMIO",
  "3/10 PRÊMIO",
  "4/5 PRÊMIO",
  "4/6 PRÊMIO",
  "4/7 PRÊMIO",
  "4/8 PRÊMIO",
  "4/9 PRÊMIO",
  "4/10 PRÊMIO",
  "5/6 PRÊMIO",
  "5/7 PRÊMIO",
  "5/8 PRÊMIO",
  "5/9 PRÊMIO",
  "5/10 PRÊMIO",
  "6/7 PRÊMIO",
  "6/8 PRÊMIO",
  "6/9 PRÊMIO",
  "6/10 PRÊMIO",
  "7/8 PRÊMIO",
  "7/9 PRÊMIO",
  "7/10 PRÊMIO",
  "8/9 PRÊMIO",
  "8/10 PRÊMIO",
  "9/10 PRÊMIO",
];

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Resultado Loterias",
  path: "/base/reports/lotteries",
  apiBase: "/bancas/reports/lotteries",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {new Date(props?.value).toLocaleDateString("pt-BR")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  /**
   * HANDLERS
   */

  const handleClickDelete = async (id: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete(configs.apiBase + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <Link
        to="#"
        className="action-icon"
        onClick={() => handleClickDelete(object?.idPromotion)}
      >
        {" "}
        <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
      </Link>
    </React.Fragment>
  );
};

/**
 * COMPONENT
 */
const ReportLottery = () => {
  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useToast();

  const dispatch = useDispatch<AppDispatch>();
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formCategory, setFormCategory] = useState<string>("");
  const [lotteries, setLotteries] = useState<any>(null);
  const [modalities, setModalities] = useState<any>(null);

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */

  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const handleSearch = async () => {
    const payload = {
      startDate: formStartDate,
      endDate: formEndDate,
      idCategory: formCategory,
    };
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar relatório.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    try {
      await api.get("/bancas/lotteries", null)?.then((response) => {
        setLotteries(response.data);
      });
      await api.get("/bancas/modalities", null).then((response) => {
        setModalities(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  }, [setData]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  const categoriesOrdered = lotteries?.categories?.sort((a: any, b: any) => {
    return a.title > b.title ? 1 : -1;
  });

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Text fontWeight={"bold"}>Período:</Text>
                <Col sm={12}>
                  <Col sm={6} className="mb-2">
                    <Input
                      className="form-control"
                      type="date"
                      value={formStartDate}
                      onChange={(e) => setFormStartDate(e.target.value)}
                    />
                  </Col>
                  <Col sm={6} className="mb-2">
                    <Input
                      className="form-control"
                      type="date"
                      value={formEndDate}
                      onChange={(e) => setFormEndDate(e.target.value)}
                    />
                  </Col>
                  <Col sm={6} className="mb-2">
                    <Select
                      placeholder="Selecione uma loteria..."
                      onChange={(e) => setFormCategory(e.target.value)}
                      value={formCategory}
                    >
                      {categoriesOrdered?.map((category: any) => {
                        return (
                          <option value={category.idCategory}>
                            {category.title}
                          </option>
                        );
                      })}
                    </Select>
                  </Col>
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch()}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>

              {layout.loading || data.length === 0 ? (
                <LoaderTable />
              ) : (
                <TableContainer
                  border="1px solid #cccccc"
                  borderRadius={6}
                  p={2}
                >
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Loteria</Th>
                        <Th isNumeric>Vendas</Th>
                        <Th isNumeric>Prêmios</Th>
                        <Th isNumeric>Resultado</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.map((result) => (
                        <Tr>
                          <Td>{result.title}</Td>
                          <Td isNumeric>
                            <Badge colorScheme="green">
                              R$ {formatCurrency(result.amount || 0)}
                            </Badge>
                          </Td>
                          <Td isNumeric>
                            <Badge colorScheme="orange">
                              R$ {formatCurrency(result.total_award || 0)}
                            </Badge>
                          </Td>
                          <Td isNumeric>
                            <Badge
                              colorScheme={result.result > 0 ? "green" : "red"}
                            >
                              R$ {formatCurrency(result.result || 0)}
                            </Badge>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th></Th>
                        <Th isNumeric>
                          R${" "}
                          {formatCurrency(sumTotalValue(data, "amount") || 0)}
                        </Th>
                        <Th isNumeric>
                          R${" "}
                          {formatCurrency(
                            sumTotalValue(data, "total_award") || 0
                          )}
                        </Th>
                        <Th isNumeric>
                          <Badge
                            colorScheme={
                              sumTotalValue(data, "result") > 0
                                ? "green"
                                : "red"
                            }
                          >
                            R${" "}
                            {formatCurrency(sumTotalValue(data, "result") || 0)}
                          </Badge>
                        </Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ReportLottery;
