import React from "react";

/**
 * Renders the preloader
 */
const LoaderFull = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 99999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="spinner" />
    </div>
  );
};

export default LoaderFull;
