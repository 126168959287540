import { Form, FormSelect } from "react-bootstrap";
import Select from "react-select";

import { useCallback, useEffect, useState } from "react";
import {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
} from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";

interface FormInputProps {
  name: string;
  label?: string;
  errors?: FieldErrors;
  options: any[];
  labelKey?: string;
  multiple?: boolean;
  containerClass?: string;
  defaultValue?: any;
  onChange?: (data?: any) => void;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<any>;
  resetField: UseFormResetField<any>;
  removeFirstOption?: boolean;
}

const FormSelect2Input = ({
  name,
  label,
  errors,
  options,
  labelKey,
  multiple,
  containerClass,
  defaultValue,
  onChange,
  setValue,
  register,
  getValues,
  resetField,
  removeFirstOption,
  ...otherProps
}: FormInputProps) => {
  /**
   * HOOKS
   */

  /**
   * CALLBACKS
   */
  const handleChange = useCallback(
    (selected: any) => {
      const newValue = selected;

      setValue(name, newValue);
      resetField(name, { defaultValue: newValue });

      if (onChange) onChange(newValue || null);
    },
    [name, onChange, resetField, setValue]
  );

  const newOptions = removeFirstOption
    ? options
    : [{ label: "Selecione...", value: "" }, ...options];

  return (
    <>
      <Form.Group controlId={`form-${name}`} className={containerClass}>
        <Form.Label>{label}</Form.Label>

        {/* <FormSelect
          onChange={(e: any) => handleChange(e.target.value)}
          multiple={multiple}
          id={`form-${name}`}
          value={getValues(name)}
          {...otherProps}
          {...(errors && errors[name]
            ? { isInvalid: true, className: "is-invalid" }
            : {})}
        >
          {!removeFirstOption ? <option value="">Selecione...</option> : null}
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </FormSelect> */}

        <Select
          classNamePrefix="select"
          defaultValue={newOptions[0]}
          isClearable={removeFirstOption ? false : true}
          isSearchable={true}
          options={newOptions}
          placeholder="Selecione..."
          onChange={(e: any, triggeredAction: any) => {
            if (triggeredAction.action === "clear") {
              // Clear happened
              if (removeFirstOption) {
                return;
              }
              return handleChange("");
            } else {
              return handleChange(String(e.value));
            }
          }}
          id={`form-${name}`}
          value={options.find((opt) => opt.value === getValues(name))}
          {...otherProps}
          {...(errors && errors[name]
            ? { isInvalid: true, className: "is-invalid" }
            : {})}
        />

        {errors && errors[name] ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors[name]["message"]}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
};

export default FormSelect2Input;
