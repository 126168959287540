import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Wizard, Steps, Step } from "react-albus";
import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, Button, ProgressBar } from "react-bootstrap";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import { useRegisterForm } from "../../../hooks";
import FormSelectInput from "../../../components/FormSelect";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
import { useToast } from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import LoaderTable from "../../../components/LoaderTable";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Contas Pix",
  path: "/base/pix-accounts/",
  create: "/base/pix-accounts/new/",
  update: "/base/pix-accounts/update/",
  apiBase: "/bancas/pix-accounts/",
};

/**
 * INTERFACES
 */
interface FormType {
  idProvider: number;
  description: string;
  username: string;
  password: string;
  clientId: string;
  secret: string;
  key: string;
  minimal: string;
  status: string;
}

interface Provider {
  idProvider: number;
  name: string;
}

interface ApiCreationData extends FormType {}

/**
 * FORM VALIDATIONS
 */
const resolver = yupResolver(
  yup.object().shape({
    idProvider: yup.number().required("Selecione um Provedor"),
    description: yup.string().required("Insira a descrição"),
    username: yup.string().nullable(),
    password: yup.string().nullable(),
    clientId: yup.string().nullable(),
    secret: yup.string().nullable(),
    key: yup.string().nullable(),
    minimal: yup.string().required("Insira o Valor mínimo de recarga"),
    status: yup.string().required("Escolha um status"),
  })
);

const WizardForm = ({ idUpdate }: any) => {
  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const myForm = useRegisterForm<FormType>({ resolver });

  const [providers, setProviders] = useState<Provider[]>([]);
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoadUpdate = useCallback(async () => {
    dispatch(toggleLoader(true));

    await api
      .get(configs.apiBase + idUpdate, null)
      .then((response) => {
        const data: any = response?.data ?? {};

        myForm.setValue("idProvider", data?.idProvider);
        myForm.setValue("description", data?.description);
        myForm.setValue("username", data?.username);
        myForm.setValue("password", data?.password);
        myForm.setValue("clientId", data?.clientId);
        myForm.setValue("secret", data?.secret);
        myForm.setValue("key", data?.key);
        myForm.setValue("minimal", formatCurrency(data?.minimal));
        myForm.setValue("status", data?.status);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        dispatch(toggleLoader(false));

        navigate(configs.path);
      });
    dispatch(toggleLoader(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUpdate]);

  const handlePageLoad = useCallback(async () => {
    if (idUpdate) handlePageLoadUpdate();
    dispatch(toggleLoader(true));

    await api
      .get("/bancas/providers", null)
      ?.then((response) => {
        setProviders(response?.data ?? []);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar lista de provedores.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  }, [idUpdate, handlePageLoadUpdate]);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  /**
   * AUXILIARS
   */
  const mountApiData = useCallback((): FormType => {
    return {
      ...myForm.getValues(),
      minimal: myForm.getValues().minimal.replace(/\./g, "").replace(",", "."),
    };
  }, [myForm]);

  /**
   * ACTIONS
   */
  const handleSubmit = useCallback(async () => {
    dispatch(toggleLoader(true));
    if (!idUpdate) {
      return api
        .create(configs.apiBase, mountApiData())
        .then(() => {
          dispatch(toggleLoader(false));
          navigate(configs.path);
          toast({
            title: "Registro adicionado com sucesso.",
            description: "O registro foi adicionado com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          // Exibe um Toast de erro
          dispatch(toggleLoader(false));

          toast({
            title: "Erro ao adicionar registro.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }

    await api
      .update(configs.apiBase + idUpdate, mountApiData())
      .then(() => {
        dispatch(toggleLoader(false));

        navigate(configs.path);
        toast({
          title: "Registro atualizado com sucesso.",
          description: "O registro foi atualizado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        dispatch(toggleLoader(false));

        toast({
          title: "Erro ao atualizar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [idUpdate, mountApiData, navigate]);

  return (
    <Card>
      <Card.Body>
        <LoaderTable />
        <h4 className="header-title mb-3">Informações</h4>

        <Wizard
          render={({ step, steps }) => (
            <React.Fragment>
              <ProgressBar
                animated
                striped
                variant="success"
                now={((steps.indexOf(step) + 1) / steps.length) * 100}
                className="mb-3 progress-sm"
              />

              <Steps>
                <Step
                  id="infos"
                  render={({ next }) => (
                    <form onSubmit={myForm.handleSubmit(next)}>
                      <FormSelectInput
                        name="idProvider"
                        label="Selecione um provedor *"
                        multiple={false}
                        labelKey={"label"}
                        containerClass={"mb-3"}
                        resetField={myForm.resetField}
                        getValues={myForm.getValues}
                        setValue={myForm.setValue}
                        register={myForm.register}
                        errors={myForm.errors}
                        options={providers.map((item: any) => {
                          return {
                            label: item.name,
                            value: item.idProvider,
                          };
                        })}
                      />
                      <FormInput
                        key="description"
                        type="text"
                        name="description"
                        label="Descrição *"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={myForm.register}
                        control={myForm.control}
                        errors={myForm.errors}
                      />
                      <FormInput
                        key="username"
                        type="text"
                        name="username"
                        label="Usuário"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={myForm.register}
                        control={myForm.control}
                        errors={myForm.errors}
                      />
                      <FormInput
                        key="password"
                        type="text"
                        name="password"
                        label="Senha"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={myForm.register}
                        control={myForm.control}
                        errors={myForm.errors}
                      />
                      <FormInput
                        key="clientId"
                        type="text"
                        name="clientId"
                        label="Client ID"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={myForm.register}
                        control={myForm.control}
                        errors={myForm.errors}
                      />
                      <FormInput
                        key="secret"
                        type="text"
                        name="secret"
                        label="Client Secret"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={myForm.register}
                        control={myForm.control}
                        errors={myForm.errors}
                      />
                      <FormInput
                        key="key"
                        type="text"
                        name="key"
                        label="Chave Pix"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={myForm.register}
                        control={myForm.control}
                        errors={myForm.errors}
                      />
                      <FormInput
                        key="minimal"
                        type="text"
                        name="minimal"
                        label="Valor Mínimo de Recarga R$ *"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={myForm.register}
                        control={myForm.control}
                        errors={myForm.errors}
                        defaultValue={"0,00"}
                        onChange={(e: any) => {
                          const parsedValue = parseCurrency(e.target.value);
                          e.target.value = formatCurrency(parsedValue);
                        }}
                      />
                      <FormSelectInput
                        name="status"
                        label="Selecione um status *"
                        multiple={false}
                        labelKey={"label"}
                        containerClass={"mb-3"}
                        resetField={myForm.resetField}
                        getValues={myForm.getValues}
                        setValue={myForm.setValue}
                        register={myForm.register}
                        errors={myForm.errors}
                        options={[
                          {
                            label: "Ativada",
                            value: "Ativada",
                            className: "text-green",
                          },
                          {
                            label: "Desativada",
                            value: "Desativada",
                            className: "text-red",
                          },
                        ]}
                      />

                      <ul className="list-inline wizard mb-0">
                        <li className="next list-inline-item float-end">
                          <Button type="submit" variant="success">
                            Próximo
                          </Button>
                        </li>
                      </ul>
                    </form>
                  )}
                />

                <Step
                  id="confirmation"
                  render={({ previous }) => (
                    <Row>
                      <Col sm={12}>
                        <div className="text-center">
                          <h2 className="mt-0">
                            <i className="mdi mdi-check-all"></i>
                          </h2>
                          <h3 className="mt-0">Finalizado!</h3>

                          <p className="w-75 mb-2 mx-auto">
                            Deseja realmente prosseguir com a criação?
                          </p>
                        </div>
                      </Col>

                      <Col sm={12}>
                        <ul className="list-inline wizard mb-0">
                          <li className="previous list-inline-item">
                            <Button
                              type="submit"
                              variant="info"
                              onClick={previous}
                            >
                              Anterior
                            </Button>
                          </li>

                          <li className="next list-inline-item float-end">
                            <Button
                              type="submit"
                              variant="success"
                              onClick={handleSubmit}
                            >
                              Enviar
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  )}
                />
              </Steps>
            </React.Fragment>
          )}
        />
      </Card.Body>
    </Card>
  );
};

const PixAccountsCreate = () => {
  const { id } = useParams();
  const [idUpdate, setIdUpdate] = useState<string | undefined>(undefined);

  /**
   * LIFE CYCLE HELPER
   */
  const handlePageLoad = useCallback(() => {
    setIdUpdate(id);
  }, [id]);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  return (
    <React.Fragment>
      <PageTitle
        title={!idUpdate ? "Adicionar Registro" : "Atualizar Registro"}
        breadCrumbItems={[
          { label: configs.title, path: configs.path },
          {
            active: true,
            label: !idUpdate ? "Adicionar Registro" : "Atualizar Registro",
            path: !idUpdate ? configs.create : configs.update,
          },
        ]}
      />

      <Row>
        <Col lg={12}>
          <WizardForm idUpdate={idUpdate} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PixAccountsCreate;
