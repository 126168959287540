import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

/**
 * Renders the preloader
 */
const LoaderSimple = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 9,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="spinner" />
    </div>
  );
};

export default LoaderSimple;
