import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

/**
 * Renders the preloader
 */
const LoaderTable = () => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  if (layout.loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#ffffff",
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 9,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="spinner" />
      </div>
    );
  }

  return null;
};

export default LoaderTable;
