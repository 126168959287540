import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Wizard, Steps, Step } from "react-albus";
import {
  Row,
  Col,
  Card,
  Button,
  ProgressBar,
  InputGroup,
} from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import { useRegisterForm } from "../../../hooks";
import FormSelectInput from "../../../components/FormSelect";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import LoaderTable from "../../../components/LoaderTable";
import FormSelect2Input from "../../../components/FormSelect2";
/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Usuários Banca",
  path: "/base/users",
  create: "/base/users/new/",
  update: "/base/users/update/",
  apiBase: "/bancas/users/",
};

/**
 * INTERFACES
 */
interface FirstStepWizard {
  fullName: string;
  username: string;
  password: string;
  idPermission: number;
  idRegion: number;
  idCollector: number;
}

interface ApiCreationData extends FirstStepWizard {}

/**
 * FORM VALIDATIONS
 */
const resolver = yupResolver(
  yup.object().shape({
    fullName: yup.string().required("Por favor, insira um nome"),
    username: yup.string().required("Por favor, insira um usuário"),
    password: yup.string(),
    idPermission: yup.number().required("Por favor, selecione uma permissão"),
    idRegion: yup.mixed().when("idPermission", {
      is: 7,
      then: yup.number().required("Por favor, selecione uma região"),
      otherwise: yup.mixed().notRequired(),
    }),
    idCollector: yup.mixed().when("idPermission", {
      is: 3,
      then: yup.number().required("Por favor, selecione um cobrador"),
      otherwise: yup.mixed().notRequired(),
    }),
  })
);

const WizardForm = ({ idUpdate }: any) => {
  const dispatch = useDispatch<AppDispatch>();

  /**
   * HOOKS
   */
  const [permissions, setPermissions] = useState([]);
  const [permissionSelected, setPermissionSelected] = useState("");
  const [regions, setRegions] = useState([]);
  const [collectors, setCollectors] = useState([]);

  const navigate = useNavigate();
  const firstWizard = useRegisterForm<FirstStepWizard>({ resolver });
  const toast = useToast();
  const user = JSON.parse(localStorage.getItem("@Baron:user") || "{}").userData;

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoadUpdate = useCallback(async () => {
    dispatch(toggleLoader(true));
    await api
      .get(configs.apiBase + idUpdate, null)
      .then((response) => {
        const data: any = response?.data ?? {};

        firstWizard.setValue("fullName", data?.fullName);
        firstWizard.setValue("username", data?.username);
        firstWizard.setValue("password", data?.password);
        firstWizard.setValue("idPermission", data?.idPermission);
        firstWizard.setValue("idRegion", data?.idRegion);
        firstWizard.setValue("idCollector", data?.idCollector);
        setPermissionSelected(String(data?.idPermission));
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        dispatch(toggleLoader(false));

        navigate(configs.path);
      });
    dispatch(toggleLoader(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUpdate]);

  const handlePageLoad = useCallback(async () => {
    await api.get("/bancas/permissions", null)?.then((response) => {
      setPermissions(response?.data ?? []);
    });
    await api.get("/bancas/regions", null)?.then((response) => {
      setRegions(response?.data ?? []);
    });
    await api
      .get("/bancas/users-collectors", null)
      ?.then((response) => {
        setCollectors(response?.data ?? []);
        if (!idUpdate)
          firstWizard.setValue("idCollector", response?.data[0]?.idUnit);
      })
      .catch((error: any) => {
        toast({
          title: "Não foi possível realizar a busca de promotores",
          description: error,
          status: "error",
          duration: 5000,
        });
      });

    if (idUpdate) handlePageLoadUpdate();
  }, [idUpdate, handlePageLoadUpdate]);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  /**
   * AUXILIARS
   */
  const mountApiData = useCallback((): ApiCreationData => {
    return {
      ...firstWizard.getValues(),
    };
  }, [firstWizard]);

  /**
   * ACTIONS
   */
  const handleSubmit = useCallback(async () => {
    dispatch(toggleLoader(true));
    let apiData = mountApiData();
    if (apiData.username) {
      apiData.username = apiData.username.replace(`@${user.base.alias}`, "");
    }

    if (!idUpdate) {
      return await api
        .create(configs.apiBase, mountApiData())
        .then(() => {
          dispatch(toggleLoader(false));
          navigate(configs.path);
          toast({
            title: "Registro adicionado com sucesso.",
            description: "O registro foi adicionado com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          dispatch(toggleLoader(false));

          // Exibe um Toast de erro
          toast({
            title: "Erro ao adicionar registro.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }

    await api
      .update(configs.apiBase + idUpdate, mountApiData())
      .then(() => {
        dispatch(toggleLoader(false));
        navigate(configs.path);
        toast({
          title: "Registro atualizado com sucesso.",
          description: "O registro foi atualizado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        dispatch(toggleLoader(false));
        toast({
          title: "Erro ao atualizar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [idUpdate, mountApiData, navigate]);

  return (
    <Card>
      <Card.Body>
        <LoaderTable />
        <h4 className="header-title mb-3">Informações</h4>

        <Wizard
          render={({ step, steps }) => (
            <React.Fragment>
              <ProgressBar
                animated
                striped
                variant="success"
                now={((steps.indexOf(step) + 1) / steps.length) * 100}
                className="mb-3 progress-sm"
              />

              <Steps>
                <Step
                  id="infos"
                  render={({ next }) => (
                    <form onSubmit={firstWizard.handleSubmit(next)}>
                      <Row>
                        <Col md={6}>
                          <FormInput
                            key="fullName"
                            type="text"
                            name="fullName"
                            label="Nome Completo *"
                            className="form-control"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                          />
                        </Col>

                        <Col md={6}>
                          <FormSelectInput
                            name="idPermission"
                            label="Selecione uma permissão *"
                            multiple={false}
                            labelKey={"label"}
                            containerClass={"mb-3"}
                            resetField={firstWizard.resetField}
                            getValues={firstWizard.getValues}
                            setValue={firstWizard.setValue}
                            register={firstWizard.register}
                            errors={firstWizard.errors}
                            options={permissions.map((item: any) => {
                              return {
                                label: item.title,
                                value: item.idPermission,
                              };
                            })}
                            onChange={(e: any) => {
                              setPermissionSelected(e);
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          md={4}
                          xs={6}
                          style={{ marginRight: 0, paddingRight: 0 }}
                        >
                          {/* <InputGroup> */}
                          <FormInput
                            style={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              paddingRight: 0,
                            }}
                            key="username"
                            type="text"
                            name="username"
                            label="Usuário"
                            className="form-control"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                          />
                          {/* <InputGroup.Text
                              style={{ marginTop: 29, height: "36px" }} // replace '50px' with the height you want
                            >
                              @{user.base.alias}
                            </InputGroup.Text>
                          </InputGroup> */}
                        </Col>
                        <Col
                          md={2}
                          xs={6}
                          style={{ margin: 0, padding: 0, paddingRight: 12 }}
                        >
                          <Text
                            textAlign={"left"}
                            alignItems={"center"}
                            borderTop={"1px solid #ced4da"}
                            borderBottom={"1px solid #ced4da"}
                            borderRight={"1px solid #ced4da"}
                            backgroundColor={"#F8F9FA"}
                            marginTop={29}
                            style={{
                              padding: 0,
                              paddingLeft: 3,
                              paddingTop: 5,
                              height: "37px",
                              borderTopRightRadius: 4,
                              borderBottomRightRadius: 4,
                            }}
                          >
                            @{user.base.alias}
                          </Text>
                        </Col>

                        <Col md={6}>
                          <FormInput
                            key="password"
                            type="password"
                            name="password"
                            label="Senha *"
                            className="form-control"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                          />
                        </Col>
                      </Row>

                      <Row>
                        {permissionSelected === "7" && (
                          <Col md={12}>
                            <FormSelectInput
                              name="idRegion"
                              label="Selecione uma Região *"
                              multiple={false}
                              labelKey={"label"}
                              containerClass={"mb-3"}
                              resetField={firstWizard.resetField}
                              getValues={firstWizard.getValues}
                              setValue={firstWizard.setValue}
                              register={firstWizard.register}
                              errors={firstWizard.errors}
                              options={regions?.map((item: any) => {
                                return {
                                  label: item.name,
                                  value: item.idRegion,
                                };
                              })}
                            />
                          </Col>
                        )}

                        {permissionSelected === "3" && (
                          <Col md={12}>
                            <FormSelect2Input
                              name="idCollector"
                              label="Selecione um promotor"
                              multiple={false}
                              labelKey={"label"}
                              containerClass={"mb-3"}
                              resetField={firstWizard.resetField}
                              getValues={firstWizard.getValues}
                              setValue={firstWizard.setValue}
                              register={firstWizard.register}
                              errors={firstWizard.errors}
                              options={collectors?.map((item: any) => {
                                return {
                                  label: item.fullName,
                                  value: item.idUnit,
                                };
                              })}
                            />
                          </Col>
                        )}
                      </Row>

                      <ul className="list-inline wizard mb-0">
                        <li className="next list-inline-item float-end">
                          <Button type="submit" variant="success">
                            Próximo
                          </Button>
                        </li>
                      </ul>
                    </form>
                  )}
                />

                <Step
                  id="confirmation"
                  render={({ previous }) => (
                    <Row>
                      <Col sm={12}>
                        <div className="text-center">
                          <h2 className="mt-0">
                            <i className="mdi mdi-check-all"></i>
                          </h2>
                          <h3 className="mt-0">Finalizado!</h3>

                          <p className="w-75 mb-2 mx-auto">
                            Deseja realmente prosseguir com a criação?
                          </p>
                        </div>
                      </Col>

                      <Col sm={12}>
                        <ul className="list-inline wizard mb-0">
                          <li className="previous list-inline-item">
                            <Button
                              type="submit"
                              variant="info"
                              onClick={previous}
                            >
                              Anterior
                            </Button>
                          </li>

                          <li className="next list-inline-item float-end">
                            <Button
                              type="submit"
                              variant="success"
                              onClick={handleSubmit}
                            >
                              Enviar
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  )}
                />
              </Steps>
            </React.Fragment>
          )}
        />
      </Card.Body>
    </Card>
  );
};

const UserBaseCreate = () => {
  const { id } = useParams();
  const [idUpdate, setIdUpdate] = useState<string | undefined>(undefined);

  /**
   * LIFE CYCLE HELPER
   */
  const handlePageLoad = useCallback(() => {
    setIdUpdate(id);
  }, [id]);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  return (
    <React.Fragment>
      <PageTitle
        title={!idUpdate ? "Adicionar Usuário" : "Atualizar Usuário"}
        breadCrumbItems={[
          { label: configs.title, path: configs.path },
          {
            active: true,
            label: !idUpdate ? "Adicionar Novo" : "Atualizar",
            path: !idUpdate ? configs.create : configs.update,
          },
        ]}
      />

      <Row>
        <Col lg={12}>
          <WizardForm idUpdate={idUpdate} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserBaseCreate;
