import * as yup from "yup";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Wizard, Steps, Step } from "react-albus";
import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, Button, ProgressBar } from "react-bootstrap";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import { useRegisterForm } from "../../../hooks";
import {
  Table,
  AbsoluteCenter,
  Box,
  Center,
  Divider,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  SelectField,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
import LoaderTable from "../../../components/LoaderTable";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Tabelas de Pagamento",
  path: "/base/payment-tables/",
  create: "/base/payment-tables/new/",
  update: "/base/payment-tables/update/",
  duplicate: "/base/payment-tables/duplicate/",
  apiBase: "/bancas/payment-tables/",
};

/**
 * INTERFACES
 */
interface FirstStepWizard {
  name: string;
}

/**
 * FORM VALIDATIONS
 */
const resolver = yupResolver(
  yup.object().shape({
    name: yup.string().required("Insira um nome"),
  })
);

const WizardForm = ({ idUpdate }: any) => {
  const dispatch = useDispatch<AppDispatch>();

  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const myForm = useRegisterForm<FirstStepWizard>({ resolver }); // Substitua usePaymentTableForm pelo hook apropriado
  const toast = useToast();
  const [modalities, setModalities] = useState([]);
  const removeModalitiesCodes = [
    "LOT16",
    "LOT22",
    "LOT21",
    "LOT20",
    "LOT19",
    "LOT18",
    "LOT17",
    "SEN40",
    "SEN35",
    "SEN30",
    "SEN25",
    "SEN20",
    "SEN19",
    "SEN18",
    "SEN17",
    "SEN16",
    "SEN15",
    "SEN14",
    "SEN13",
    "QUI45",
    "QUI40",
    "QUI35",
    "QUI30",
    "QUI25",
    "QUI20",
    "QUI19",
    "QUI18",
    "QUI17",
    "QUI16",
    "QUI15",
    "QUI14",
    "QUI13",
    "QUI12",
  ];

  const { pathname } = useLocation();
  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoadUpdate = useCallback(() => {
    dispatch(toggleLoader(true));
    api
      .get(configs.apiBase + idUpdate, null)
      ?.then((response) => {
        const data: any = response?.data ?? {};

        myForm.setValue("name", data?.name);

        data.cotations.map((cotation) => {
          return myForm.setValue(
            `cotations[${cotation.idModality}]`,
            formatCurrency(cotation.value)
          );
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        dispatch(toggleLoader(false));

        navigate(configs.path);
      });
    dispatch(toggleLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUpdate]);

  const handlePageLoad = useCallback(async () => {
    if (idUpdate) handlePageLoadUpdate();
    dispatch(toggleLoader(true));

    await api
      .get("/bancas/modalities", null)
      ?.then((response) => {
        setModalities(response?.data ?? []);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar lista de modalidades",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  }, [idUpdate, handlePageLoadUpdate]);
  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  /**
   * AUXILIARS
   */
  const mountApiData = useCallback((): FirstStepWizard => {
    return {
      ...myForm.getValues(),
    };
  }, [myForm]);

  /**
   * ACTIONS
   */

  const handleSubmit = useCallback(async () => {
    dispatch(toggleLoader(true));
    const dataForm = mountApiData();

    const cotationsWithCode = Object.keys(dataForm.cotations).map((key) => {
      return {
        idModality: Number(key),
        value: dataForm.cotations[key]
          ? parseCurrency(dataForm.cotations[key])
          : null,
      };
    });

    const payload = {
      ...dataForm,
      cotations: cotationsWithCode,
    };
    if (!idUpdate) {
      return api
        .create(configs.apiBase, payload)
        .then(() => {
          dispatch(toggleLoader(false));
          navigate(configs.path);
          toast({
            title: "Registro adicionado com sucesso.",
            description: "O registro foi adicionado com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          dispatch(toggleLoader(false));

          // Exibe um Toast de erro
          toast({
            title: "Erro ao adicionar registro.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }

    if (pathname.includes("duplicate")) {
      return api
        .create(configs.apiBase, payload)
        .then(() => {
          dispatch(toggleLoader(false));
          navigate(configs.path);
          toast({
            title: "Registro adicionado com sucesso.",
            description: "O registro foi adicionado com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          dispatch(toggleLoader(false));

          // Exibe um Toast de erro
          toast({
            title: "Erro ao adicionar registro.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }

    await api
      .update(configs.apiBase + idUpdate, payload)
      .then(() => {
        dispatch(toggleLoader(false));
        navigate(configs.path);
        toast({
          title: "Registro atualizado com sucesso.",
          description: "O registro foi atualizado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        dispatch(toggleLoader(false));
        toast({
          title: "Erro ao atualizar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [idUpdate, mountApiData, navigate]);

  return (
    <Card>
      <Card.Body>
        <h4 className="header-title mb-3">Nova tabela de pagamento</h4>

        <Wizard
          render={({ step, steps }) => (
            <React.Fragment>
              <ProgressBar
                animated
                striped
                variant="success"
                now={((steps.indexOf(step) + 1) / steps.length) * 100}
                className="mb-3 progress-sm"
              />

              <Steps>
                <Step
                  id="infos"
                  render={({ next }) => (
                    <form onSubmit={myForm.handleSubmit(next)}>
                      <Row>
                        <Col>
                          <Card>
                            <LoaderTable />
                            <Row md={6} className="mb-4"></Row>
                            <Row>
                              <Col md={12}>
                                <FormInput
                                  key="name"
                                  type="text"
                                  name="name"
                                  label="Nome da tabela *"
                                  className="form-control"
                                  containerClass={"mb-3"}
                                  register={myForm.register}
                                  control={myForm.control}
                                  errors={myForm.errors}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Box position="relative" padding="1">
                                  <Divider />
                                  <AbsoluteCenter
                                    bg="white"
                                    px="4"
                                    fontWeight={"bold"}
                                  >
                                    COTAÇÕES
                                  </AbsoluteCenter>
                                </Box>
                                <Center>
                                  <TableContainer width={"100%"}>
                                    <Table size="sm">
                                      <Thead>
                                        <Tr>
                                          {/* <Th>#</Th> */}
                                          <Th>Modalidade</Th>
                                          <Th w={50}>Cotação</Th>
                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        {modalities.length > 0 &&
                                          modalities
                                            .filter(
                                              (modality) =>
                                                !removeModalitiesCodes.includes(
                                                  modality.code
                                                )
                                            )
                                            .map((cotation: any) => {
                                              return (
                                                <Tr
                                                  key={cotation.code}
                                                  _hover={{ bg: "gray.200" }}
                                                >
                                                  {/* <Td>{cotation.code}</Td> */}
                                                  <Td>{cotation.name}</Td>
                                                  <FormInput
                                                    key={`cotations[${cotation.idModality}]`}
                                                    name={`cotations[${cotation.idModality}]`}
                                                    type="text"
                                                    maxLength={9}
                                                    className="form-control"
                                                    containerClass={"mb-3"}
                                                    defaultValue={"0,00"}
                                                    register={myForm.register}
                                                    control={myForm.control}
                                                    errors={myForm.errors}
                                                    onChange={(e: any) => {
                                                      return (e.target.value = `${formatCurrency(
                                                        parseCurrency(
                                                          e.target.value
                                                        )
                                                      )}`);
                                                    }}
                                                  />
                                                </Tr>
                                              );
                                            })}
                                      </Tbody>
                                    </Table>
                                  </TableContainer>
                                </Center>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>

                      <ul className="list-inline wizard mb-0">
                        <li className="next list-inline-item float-end">
                          <Button type="submit" variant="success">
                            Próximo
                          </Button>
                        </li>
                      </ul>
                    </form>
                  )}
                />

                <Step
                  id="confirmation"
                  render={({ previous }) => (
                    <Row>
                      <Col sm={12}>
                        <div className="text-center">
                          <h2 className="mt-0">
                            <i className="mdi mdi-check-all"></i>
                          </h2>
                          <h3 className="mt-0">Finalizado!</h3>

                          <p className="w-75 mb-2 mx-auto">
                            Deseja realmente prosseguir com a criação?
                          </p>
                        </div>
                      </Col>

                      <Col sm={12}>
                        <ul className="list-inline wizard mb-0">
                          <li className="previous list-inline-item">
                            <Button
                              type="submit"
                              variant="info"
                              onClick={previous}
                            >
                              Anterior
                            </Button>
                          </li>

                          <li className="next list-inline-item float-end">
                            <Button
                              type="submit"
                              variant="success"
                              onClick={handleSubmit}
                            >
                              Enviar
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  )}
                />
              </Steps>
            </React.Fragment>
          )}
        />
      </Card.Body>
    </Card>
  );
};

const PaymentTablesCreate = () => {
  const { id } = useParams();
  const [idUpdate, setIdUpdate] = useState<string | undefined>(undefined);

  /**
   * LIFE CYCLE HELPER
   */
  const handlePageLoad = useCallback(() => {
    setIdUpdate(id);
  }, [id]);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  return (
    <React.Fragment>
      <PageTitle
        title={!idUpdate ? "Adicionar Registro" : "Atualizar Registro"}
        breadCrumbItems={[
          { label: configs.title, path: configs.path },
          {
            active: true,
            label: !idUpdate ? "Adicionar Registro" : "Atualizar Registro",
            path: !idUpdate ? configs.create : configs.update,
          },
        ]}
      />

      <Row>
        <Col lg={12}>
          <WizardForm idUpdate={idUpdate} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PaymentTablesCreate;
