import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
  Icon,
  Spacer,
  Flex,
  Tooltip,
  Checkbox,
  Box,
  Progress,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  InputGroup,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";

import Select2, { StylesConfig } from "react-select";

import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import {
  CheckIcon,
  ExternalLinkIcon,
  NotAllowedIcon,
  RepeatClockIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";
import {
  FaAccessibleIcon,
  FaHandPointUp,
  FaMoneyBillWaveAlt,
  FaMoneyCheck,
  FaPlus,
  FaSave,
} from "react-icons/fa";
import { getActionsPermission } from "../../../helpers/api/auth";
import ChartStatistics from "../../../components/ChartStatistics";
import StatisticsChartWidget from "../../../components/StatisticsChartWidget";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Resultado Roletinha",
  path: "/base/roulette/",
  apiBase: "/bancas/roulette-report/",
};

const { ptBR } = require("date-fns/locale");

/**
 * COMPONENT
 */
const RouletteReport = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  const [lotteries, setLotteries] = useState([]);
  const toast = useToast();
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();
  const { publish } = useEvent();
  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    dispatch(toggleLoader(true));
    try {
      await api
        .create(configs.apiBase, preparePayload("7d"))
        .then((response) => {
          setData(response.data ?? []);
        });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados da roleta.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const preparePayload = (type: string) => {
    let payload = {
      startDate: formStartDate,
      endDate: formEndDate,
    };
    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };
    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    } else if (type === "7d") {
      payload = {
        ...payload,
        startDate: decreaseDate(7),
        endDate: hoje,
      };
    } else if (type === "30d") {
      payload = {
        ...payload,
        startDate: decreaseDate(30),
        endDate: hoje,
      };
    } else if (type === "mes") {
      payload = {
        ...payload,
        startDate: hoje.split("-")[0] + "-" + hoje.split("-")[1] + "-01",
        endDate: hoje,
      };
    }
    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);
    return payload;
  };

  const handleSearch = async (type: string) => {
    const payload = preparePayload(type);
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados da roleta.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Row>
            <Col md={4}>
              <StatisticsWidget
                variant="info"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Arrecadado"
                stats={data?.total?.sells || 0}
                icon="mdi mdi-currency-usd"
              />
            </Col>
            <Col md={4}>
              <StatisticsWidget
                variant="danger"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Prêmios"
                stats={data?.total?.awardeds * -1 || 0}
                icon="fe-bar-chart-line"
              />
            </Col>
            <Col md={4}>
              <StatisticsWidget
                variant="success"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Resultado"
                stats={data?.total?.result || 0}
                icon="fe-bar-chart-line"
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Flex
                    direction={["column", "row"]}
                    wrap="wrap"
                    gap={2}
                    my={2}
                  >
                    <ChakraButton
                      colorScheme="orange"
                      onClick={() => handleSearch("ontem")}
                    >
                      Ontem
                    </ChakraButton>
                    <ChakraButton
                      colorScheme="yellow"
                      onClick={() => handleSearch("hoje")}
                    >
                      Hoje
                    </ChakraButton>
                    <ChakraButton
                      colorScheme="teal"
                      onClick={() => handleSearch("7d")}
                    >
                      7D
                    </ChakraButton>
                    <ChakraButton
                      colorScheme="blue"
                      onClick={() => handleSearch("30d")}
                    >
                      30D
                    </ChakraButton>
                    <ChakraButton
                      colorScheme="red"
                      onClick={() => handleSearch("mes")}
                    >
                      Mês
                    </ChakraButton>
                  </Flex>

                  <Flex direction={"column"}>
                    <Text fontWeight={"bold"} my={0} mt={1}>
                      Data inicial:
                    </Text>
                    <Input
                      placeholder="Data início"
                      size="md"
                      type="date"
                      value={formStartDate}
                      onChange={(e) => setFormStartDate(e.target.value)}
                    />

                    <Text fontWeight={"bold"} my={0} mt={1}>
                      Data Final:
                    </Text>
                    <Input
                      placeholder="Data fim"
                      size="md"
                      type="date"
                      value={formEndDate}
                      onChange={(e) => setFormEndDate(e.target.value)}
                    />
                  </Flex>

                  <Row className="my-2">
                    <Col sm={12}>
                      <Button
                        variant="primary"
                        className="waves-effect waves-light"
                        style={{ backgroundColor: "#4c695c" }}
                        onClick={() => handleSearch("default")}
                      >
                        <i className="fas fa-search me-1"></i> Buscar
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={8}>
              <StatisticsChartWidget
                description="Resultado Roleta"
                stats={data?.total?.result || 0}
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                variant="success"
                icon="fe-bar-chart-line"
                chartConfig={{
                  apxOptions: {
                    chart: {
                      id: "rouletteReport",
                    },
                    xaxis: {
                      categories: data?.days?.map((d) => d.date) || [],
                    },
                  },
                  apxSeries: [
                    {
                      name: "Resultado",
                      data: data?.days?.map((d) => d.result) || [],
                    },
                  ],
                  type: "area",
                  height: 300,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RouletteReport;
