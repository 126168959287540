import { useEffect, useState, useMemo } from "react";

// HELPERS
import { APICore } from "../helpers/api/apiCore";

const useUser = (): any | void => {
  const api = useMemo(() => new APICore(), []);

  const [user, setUser] = useState();

  useEffect(() => {
    if (api.isUserAuthenticated()) {
      setUser({ ...api.getLoggedInUser()?.userData });
    } else {
      setUser(undefined);
    }
  }, [api]);

  return user;
};

export default useUser;
