import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import { Link, Table, Td, Th, Tr, useToast } from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Painel de suporte",
  path: "/support/",
};

/**
 * COMPONENT
 */
const Support = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<any[]>([]);
  const toast = useToast();

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    await api.get("/system/support", null)?.then((response) => {
      setData(response?.data ?? []);
    });
    dispatch(toggleLoader(false));
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    const intervalId = setInterval(() => {
      handlePageLoading();
    }, 3000);

    return () => {
      clearInterval(intervalId);
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  const handleRunJob = async (routine: any) => {
    if (window.confirm("Deseja realmente executar essa rotina?") === false) {
      return;
    }

    dispatch(toggleLoader(true));
    await api
      .create(`/system/support/${routine}`, null)
      .then((response) => {
        if (response?.data) {
          toast({
            title: "Sucesso",
            description: "Rotina iniciada com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        toast({
          title: "Erro ao executar rotina",
          description: error ?? "Erro ao executar rotina",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col sm={4}>
          <Card>
            <Card.Body>
              <Table>
                <Tr>
                  <Th>Rotina</Th>
                  <Th>Ação</Th>
                </Tr>

                {data.map((routine, index) => (
                  <Tr key={index}>
                    <Td>{routine.name}</Td>
                    <Td>
                      {!routine.already ? (
                        <Link onClick={() => handleRunJob(routine.type)}>
                          <span className="badge bg-success">Rodar</span>
                        </Link>
                      ) : (
                        <span className="badge bg-danger">Executando</span>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Support;
