import React from "react";
import Select from "react-select";
import { Box } from "@chakra-ui/react";

const UnitSelect = ({ data, onChange }) => {
  const options = data
    ? data.map((item) => ({ value: item.idUnit, label: item.fullName }))
    : [];

  return (
    <Select
      placeholder="Selecione o promotor" // Adicione esta linha
      options={options}
      isSearchable
      isClearable
      onChange={onChange}
    />
  );
};

export default UnitSelect;
